import React from "react";
import { useNavigate } from "react-router-dom";
import { contextapi } from "../../helpers/contextapi";
import { axiospost } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";
import { Link } from "react-router-dom";

const Register = () => {
  const [loading, setloading] = React.useState(false);
  const firstname = React.useRef();
  const middlename = React.useRef();
  const lastname = React.useRef();
  const jamb = React.useRef();
  const emailaddress = React.useRef();
  const phonenumber = React.useRef();
  const password = React.useRef();
  const confirmpassword = React.useRef();
  const context = React.useContext(contextapi);
  const navigate = useNavigate();
  React.useEffect(() => {
    localStorage.clear();
    context.setvalues({ ...context.values, main_menu: undefined });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const _login = async () => {
    try {
      setloading(true);
      const fname = firstname.current.value;
      const mname = middlename.current.value;
      const lname = lastname.current.value;
      const email = emailaddress.current.value;
      const phone = phonenumber.current.value;
      const pword = password.current.value;
      const cpword = confirmpassword.current.value;
      const jmb = jamb.current.value;
      if (
        is_null(fname) ||
        is_null(lname) ||
        is_null(email) ||
        is_null(phone) ||
        is_null(jamb) ||
        is_null(jmb)
      ) {
        swal("warning", "Please fill the form properly", setloading);
        return;
      }
      if (pword !== cpword) {
        swal("warning", "Sorry, password does not match", setloading);
        return;
      }
      const req = await axiospost("ms_applicant", {
        firstname: fname,
        middlename: mname,
        lastname: lname,
        emailaddress: email,
        phonenumber: phone,
        jamb: jmb,
        password: pword,
      });
      if (req.status !== 200) {
        throw new Error(
          "Sorry, system could not finish processing your request"
        );
      }
      const { data } = req;
      if (data && data.type === "success") {
        const { message, type } = data;
        swal(type, message, () => navigate("/verify"), false);
      } else {
        throw new Error(data.message);
      }
    } catch ({ message }) {
      console.log(message);
      swal("warning", message, setloading);
    }
  };
  return (
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-7 mx-auto">
              <div className="auth-form-light text-left py-3 px-4 px-sm-5">
                <div className="brand-logo text-center mb-3">
                  <img src="images/logo.jpg" alt="logo" />
                  <img
                    src="images/buk.jpeg"
                    style={{ maxWidth: 100 }}
                    alt="BUK logo"
                  />
                </div>
                <div className="text-center">
                  <h4>ADAMU TAFAWA BALEWA COLLEGE OF EDUCATION, KANGERE</h4>
                  <h6 className="font-weight-light text-uppercase">
                    Admission Application Portal for Undergraduate
                  </h6>
                  <h6 className="font-weight-light text-uppercase">
                    Create your profile.
                  </h6>
                </div>
                <div className="pt-1">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="firstname">First Name</label>
                        <input
                          className="form-control form-control-lg"
                          id="firstname"
                          placeholder="First Name"
                          ref={firstname}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="middlename">Middle Name</label>
                        <input
                          className="form-control form-control-lg"
                          id="middlename"
                          placeholder="Middle Name"
                          ref={middlename}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="lastname">Last Name</label>
                        <input
                          className="form-control form-control-lg"
                          id="exampleInputEmail1"
                          placeholder="Last Name"
                          ref={lastname}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="lastname">Jamb Admission Number</label>
                        <input
                          className="form-control form-control-lg"
                          id="exampleInputEmail1"
                          placeholder="Jamb Admission Number"
                          ref={jamb}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="lastname">Email Address</label>
                        <input
                          type="email"
                          className="form-control form-control-lg"
                          id="exampleInputEmail1"
                          placeholder="Email Address"
                          ref={emailaddress}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="lastname">Phone Number</label>
                        <input
                          className="form-control form-control-lg"
                          id="exampleInputEmail1"
                          placeholder="Phone Number"
                          ref={phonenumber}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                          className="form-control form-control-lg"
                          id="exampleInputPassword1"
                          placeholder="Password"
                          type="password"
                          ref={password}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="confirmpassword">
                          Confirm Password
                        </label>
                        <input
                          className="form-control form-control-lg"
                          id="confirmpassword"
                          placeholder="Confirm Password"
                          type="password"
                          ref={confirmpassword}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    {!loading && (
                      <button
                        className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                        onClick={_login}
                      >
                        SIGN UP
                      </button>
                    )}
                    {loading && (
                      <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">
                        Please Wait...
                      </button>
                    )}
                  </div>
                  <div className="my-2 d-flex justify-content-between align-items-end">
                    <Link to="/" className="auth-link text-black">
                      Login?
                    </Link>
                    <Link to="/forgot" className="auth-link text-black">
                      Forgot password?
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
