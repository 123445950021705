import React from "react";
import AdmissionLetter from "../../components/AdmissionLetter";
import Loader from "../../components/Loader";
import { contextapi } from "../../helpers/contextapi";
import { axiosget } from "../../helpers/httprequest";
import SelectStatus from "../../components/SelectStatus";
import Swal from "sweetalert2";
import TblReceipt from "../../table/TblReceipt";

const MainReceipt = () => {
  const context = React.useContext(contextapi);
  const [data, setdata] = React.useState(undefined);
  const [loading, setloading] = React.useState(false);
  const [applicationType, setapplicationType] = React.useState(null);
  //creating function to load ip address from the API
  React.useEffect(() => {
    if (applicationType !== null) {
      if (applicationType === "Application Fee") {
        (async () => {
          setloading(true);
          try {
            const req = await axiosget("ms_application_fee/");
            if (req.data && req.data.type === undefined) {
              setdata(req.data);
              setloading(false);
              return;
            }
            throw new Error(req.data.message);
          } catch ({ message }) {
            setloading(false);
            Swal.fire({
              icon: "error",
              html: message,
              title: "ERROR ENCOUNTERED",
            });
          }
        })();
      }
    }
  }, [applicationType]);

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="row">
          <div className="col-md-12 grid-margin transparent">
            <div className="row">
              <div className="col-md-12 stretch-card transparent">
                <div className="card card-white">
                  <div className="card-body">
                    <h3 className="font-weight-bold">
                      Applicant Payment Receipts
                    </h3>
                    <hr />
                    <div className="row">
                      <div className="col-md-12">
                        <SelectStatus
                          options={[
                            {
                              name: "Application Fee",
                              value: "Application Fee",
                            },
                            { name: "Acceptance Fee", value: "Acceptance Fee" },
                          ]}
                          callback={setapplicationType}
                          label="Select Payment Type"
                        />
                      </div>
                      <div className="col-md-12 grid-margin transparent">
                        {loading ? (
                          <Loader />
                        ) : (
                          <div className="row">
                            {data && data?.length ? (
                              <TblReceipt data={data} />
                            ) : (
                              <div className="text-muted p-3">
                                Select the payment type you wish to generate
                                report for.
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainReceipt;
