import React from "react";
import Loader from "../../components/Loader";
import { contextapi } from "../../helpers/contextapi";
import { axiosget } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import TblALevel from "../../table/TblALevel";

const MainALevel = () => {
  const context = React.useContext(contextapi);
  const [data, setdata] = React.useState(undefined);
  const [loading, setloading] = React.useState(false);
  const { reloadALevel } = context;
  React.useEffect(() => {
    (async () => {
      if (!is_null(reloadALevel)) {
        try {
          setloading(true);
          const req = await axiosget("ms_applicant_alevel");
          if (req.data) {
            setdata(req.data);
          }
          setloading(false);
          context.setvalues({ ...context.values, reloadALevel: null });
        } catch ({ message }) {
          console.warn(message);
        }
      }
    })();
  }, [reloadALevel]);
  React.useEffect(() => {
    (async () => {
      try {
        setloading(true);
        const req = await axiosget("ms_applicant_alevel");
        if (req.data) {
          setdata(req.data);
        }
        setloading(false);
      } catch ({ message }) {
        console.warn(message);
      }
    })();
  }, []);

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="row">
          <div className="col-md-12 grid-margin transparent">
            <div className="row">
              <div className="col-md-12 stretch-card transparent">
                <div className="card card-white mt-2">
                  <div className="card-body">
                    <p className="fs-30 mb-4 text-center text-uppercase">
                      Student A-Level Results
                    </p>
                    {loading ? (
                      <Loader />
                    ) : (
                      <>
                        {data ? (
                          <TblALevel data={data} />
                        ) : (
                          <div className="text-gray text-center">
                            No parents or guardian added
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainALevel;
