import React from "react";
import is_null from "../../helpers/is_null";

const SelectStatus = ({ callback, options, label, active }) => {
  return (
    <div className="form-group">
      <label htmlFor="js-example-basic-single">{label}</label>
      <select
        id="js-example-basic-single"
        className="form-control form-control-lg js-example-basic-single w-100"
        onChange={(e) => callback(e.target.value)}
      >
        <option value={active}> {label}</option>
        {options?.map((v, i) => {
          const current =
            !is_null(active) && v.value === active
              ? " (Current Selection)"
              : "";
          return (
            <option key={i} value={v.value}>
              {v.name} {current}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default SelectStatus;
