import React from "react";

const ShowDiploma = ({ r }) => {
  const {
    institution,
    graduationyear,
    classification,
    course,
    cgpa,
    diplomatype,
  } = r?.results;
  return (
    <>
      <div className="row">
        <div className="col-md-4">Institution</div>
        <div className="col-md-8">{institution}</div>
      </div>
      <div className="row">
        <div className="col-md-4">Graduation Year</div>
        <div className="col-md-8">{graduationyear}</div>
      </div>
      <div className="row">
        <div className="col-md-4">Course</div>
        <div className="col-md-8">
          {diplomatype} {course}
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">Classification</div>
        <div className="col-md-8">{classification}</div>
      </div>
      <div className="row">
        <div className="col-md-4">CGPA</div>
        <div className="col-md-8">{cgpa}</div>
      </div>
    </>
  );
};

export default ShowDiploma;
