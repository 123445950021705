import React from "react";
import { useParams } from "react-router-dom";
import { axiospost } from "../../helpers/httprequest";
import { appHost } from "../../helpers/uri";

const Activate = () => {
  const [loading, setloading] = React.useState(true);
  const [error, seterror] = React.useState(undefined);
  const { id } = useParams();
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiospost(`ms_applicant/activate`, { id });
        if (req.data?.type !== "success") {
          throw new Error(req.data?.message);
        }
        setloading(false);
        window.location.href = appHost;
      } catch ({ message }) {
        seterror(message);
        setloading(false);
      }
    })();
    //eslint-disable-next-line
  }, []);
  return (
    <div>
      <>{loading && <i>Please wait...</i>}</>
      <>{!loading && <i>{error}</i>}</>
    </div>
  );
};

export default Activate;
