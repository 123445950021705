import React from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import MainReferee from "../../content/MainReferee";
import BtnCollege from "../../buttons/BtnCollege";

const Referee = () => {
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <MainReferee />
      </div>
      <BtnCollege />
    </div>
  );
};

export default Referee;
