import React from "react";

const ReceiptHeader = ({ office }) => {
  return (
    <div className="container">
      <div className="header">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <img
            className="py-2"
            src="../images/logo.jpg"
            alt="Intitution Logo"
            style={{ maxWidth: 80 }}
          />
          <div style={{ paddingTop: 10 }}>
            <h1
              style={{
                marginTop: 0,
                marginBottom: 3,
                color: "blue",
                fontSize: 40,
              }}
            >
              ADAMU TAFAWA BALEWA
            </h1>
            <h3 style={{ marginTop: 0, marginBottom: 3, color: "blue" }}>
              COLLEGE OF EDUCATION KANGERE, BAUCHI STATE
            </h3>

            <div className="text-uppercase">
              in affliation with Bayero University Kano
            </div>
            <div className="text-uppercase">Central Administration</div>
            <div className="text-uppercase">
              P.M.B 0316, BAUCHI GOMBE ROAD, KANGERE TOWN, Bauchi State, Nigeria
            </div>
          </div>

          <img
            className="py-2"
            src="../images/buk.jpeg"
            alt="Intitution Logo"
            style={{ maxWidth: 80 }}
          />
        </div>
      </div>

      <div className="lettertohead">
        <div className="">
          <div>
            <b style={{ color: "red" }}>PROVOST:</b>
          </div>
          <b className="text-uppercase">
            Dr. Buteh DAUDA Sanda{" "}
            <small style={{ fontSize: 10 }}>MICCON, NCSN</small>
          </b>
          <div>HND, MSc, PhD (ATBU)</div>
          <div>provost@coekangere.edu.ng</div>
        </div>
        <div className="">
          <div>
            <b style={{ color: "red" }}>REGISTRAR:</b>
          </div>
          <div className="text-uppercase">
            <b>
              Alh. Sambo Aliyu,{" "}
              <small style={{ fontSize: 10 }}>MNIM, FIICA</small>
            </b>
          </div>
          <div>BA (HONS) (BUK)</div>
          <div>registrar@coekangere.edu.ng</div>
        </div>
      </div>
      <div className="officename">({office})</div>
    </div>
  );
};

export default ReceiptHeader;
