import React from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import MainPayFee from "../../content/MainPayFee";

const PayFee = () => {
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <MainPayFee />
      </div>
    </div>
  );
};

export default PayFee;
