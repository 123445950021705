const result_converter = (scored) => {
  let fig = 0;
  switch (scored) {
    case "A1":
      fig = 8;
      break;
    case "B2":
      fig = 7;
      break;
    case "B3":
      fig = 6;
      break;
    case "C4":
      fig = 5;
      break;
    case "C5":
      fig = 4;
      break;
    case "C6":
      fig = 3;
      break;
    case "D7":
      fig = 2;
      break;
    case "E8":
      fig = 1;
      break;
    case "F9":
      fig = 0;
      break;
    default:
      fig = 0;
      break;
  }
  return fig;
};
export default result_converter;
