import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import { contextapi } from "../../helpers/contextapi";
import { axiosget, axiospost } from "../../helpers/httprequest";
import TblPayFee from "../../table/TblPayFee";
import swal from "../../helpers/swal";
// import is_money from "../../helpers/is_money";
import is_null from "../../helpers/is_null";
import ReactModal from "react-modal";
// import { paymentHandler } from "../../helpers/paymentHandler";
import CryptoJS from "crypto-js";
import {
  APIKEY,
  MerchantId,
  PublicKey,
  ServiceTypeId,
} from "../../components/Keys";

const MainPayFee = () => {
  const context = React.useContext(contextapi);
  const [data, setdata] = React.useState(undefined);
  const [loadRRR, setloadRRR] = React.useState(false);
  const [loading, setloading] = React.useState(false);
  const [courseinfo, secourseinfo] = React.useState(null);
  const [loadingmodal, setloadingmodal] = React.useState(false);
  const [selectedapp, setselectedapp] = React.useState(false);
  const [total, settotal] = React.useState(0);
  const [rrr, setrrr] = React.useState(0);
  const [showmodal, setshowmodal] = React.useState(false);
  const [showmodalrrr, setshowmodalrrr] = React.useState(false);
  const [trxcode, settrxcode] = React.useState(undefined);
  const [returnedprog, setreturnedprog] = React.useState([]);
  const navigate = useNavigate();
  const { user, selectedApplication } = context.values;
  React.useEffect(() => {
    (async () => {
      try {
        if (selectedApplication === undefined) {
          navigate("/dashboard");
        }
        const req = await axiosget(
          `ms_applications/single/${selectedApplication._id}`
        );
        setselectedapp(req.data);
        console.log(req.data);
      } catch ({ message }) {
        console.warn(message);
      }
    })();
    const info = JSON.parse(localStorage.getItem("applicationId"));
    secourseinfo(info);
  }, []);

  React.useEffect(() => {
    (async () => {
      try {
        setloading(true);
        const req = await axiosget(
          `ms_programme_application_fee_items/${selectedapp?.programme}`
        );

        if (req.data) {
          const { data, programme } = req.data;
          setdata(data);
          const amount = data?.map((v) => v.amount).reduce((p, a) => p + a, 0);
          settotal(amount);
          setreturnedprog(programme);
        }
        setloading(false);
      } catch ({ message }) {
        console.warn(message);
      }
    })();
  }, [selectedapp]);

  const _close = () => setshowmodal(false);
  const _closerrr = () => setshowmodalrrr(false);

  const _showrrr = () => setshowmodalrrr(true);
  const _show = () => setshowmodal(true);

  const _save = async () => {
    try {
      setloadingmodal(true);
      if (is_null(trxcode)) {
        swal(
          "warning",
          "Sorry, please enter the transaction code",
          setloadingmodal,
          false
        );
        return;
      }
      const req = await axiospost("ms_application_fee", {
        application: selectedapp._id,
        transaction: trxcode,
      });
      if (req.data?.type !== "success") {
        throw new Error(req.data?.message);
      }
      const { type, message } = req.data;
      swal(type, message, () => window.location.reload(), false);
      return;
    } catch ({ message }) {
      swal("error", message, setloadingmodal, false);
    }
  };
  const _pay = () => {
    var randomnumber = Date.now();
    _closerrr();
    var paymentEngine = window.RmPaymentEngine.init({
      key: PublicKey,
      processRrr: true,
      transactionId: randomnumber,
      channel: "CARD,USSD",
      extendedData: {
        customFields: [
          {
            name: "rrr",
            value: rrr,
          },
        ],
      },
      onSuccess: async (response) => {
        setloading(true);
        const req = await axiospost("ms_application_fee", {
          application: selectedApplication._id,
          transaction: rrr,
        });
        if (req.status !== 200) {
          swal(
            "info",
            `Sorry your transaction was successfull, but could not be stored, Please copy this transaction code ${rrr} as we will request for it`,
            undefined,
            false
          );
          setloading(false);
          return;
        }
        const { type, message } = req.data;
        if (type === "success") {
          swal(type, message, undefined, false);
          setloading(false);
          return;
        }
        swal(type, message, undefined, false);
        setloading(false);
        return;
      },
      onError: function (response) {
        swal(
          "info",
          "Transaction was not completed, window closed." +
            JSON.stringify(response),
          undefined,
          false
        );
      },
      onClose: function (response) {},
    });
    paymentEngine.showPaymentWidget();
  };

  const _generateRRR = async () => {
    try {
      setloadRRR(true);
      const serviceTypeId = ServiceTypeId;
      const d = new Date();
      const orderId = d.getTime();
      const merchantId = MerchantId;
      const apiKey = APIKEY;
      const amount = total;

      var apiHash = CryptoJS.SHA512(
        merchantId + serviceTypeId + orderId + amount + apiKey
      );
      const dataRRR = {
        serviceTypeId: serviceTypeId,
        amount: amount,
        orderId: orderId,
        payerName: `${user.firstname} ${user.middlename}  ${user.lastname}`,
        payerEmail: user?.emailaddress,
        payerPhone: user?.phonenumber,
        description: `Payment for application with application number: ${selectedApplication?.code}`,
      };

      const headerRRR = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `remitaConsumerKey=${merchantId},remitaConsumerToken=${apiHash}`,
        },
        method: "POST",
        body: JSON.stringify(dataRRR),
      };

      fetch(
        "https://login.remita.net/remita/exapp/api/v1/send/api/echannelsvc/merchant/api/paymentinit",
        headerRRR
      )
        .then((r) => r.text())
        .then(async (r) => {
          const removeF = JSON.parse(
            r.substring(r.indexOf("{"), r.lastIndexOf("}") + 1)
          );
          if (removeF?.RRR === undefined) {
            swal("error", removeF.statusMessage, undefined, false);
            return;
          }
          const fetch = await axiospost("ms_generated_rrr", {
            application: selectedApplication?._id,
            applicant: selectedApplication.applicant,
            rrr: removeF.RRR,
            data: dataRRR,
          });
          if (fetch?.status !== 200) {
            swal(
              "error",
              "Sorry, system could not store your RRR Code, please try and generate another RRR code",
              undefined,
              false
            );
            return;
          }
          if (fetch?.data?.type === "error") {
            swal("error", fetch?.data?.message, undefined, false);
            setloadRRR(false);
            return;
          }
          setrrr(removeF.RRR);
          _showrrr();
          setloadRRR(false);
        });
    } catch ({ message }) {
      setloadRRR(false);
    }
  };

  return (
    <div className="main-panel">
      <ReactModal
        isOpen={showmodal}
        className="ReactModal"
        onRequestClose={_close}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-uppercase text-center">
                Payment has been Made
              </h5>
              <span className="text-danger pointer" onClick={_close}>
                &times;
              </span>
            </div>

            <div className="modal-body">
              <>
                Since you have made payment, Please enter your RRR number for
                confirmation
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    id="exampleInputEmail2"
                    placeholder="Since you have made payment, Please enter your RRR number for confirmation"
                    onChange={(e) => settrxcode(e.target.value)}
                  />
                </div>
              </>
            </div>
            <div className="modal-footer">
              {loadingmodal ? (
                <button
                  type="button"
                  className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn "
                >
                  Please wait...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn text-uppercase"
                  onClick={_save}
                >
                  Verify Payment
                </button>
              )}
            </div>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={showmodalrrr}
        className="ReactModal"
        onRequestClose={_closerrr}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-uppercase text-center">
                Generated Payment RRR
              </h5>
              <span className="text-danger pointer" onClick={_closerrr}>
                &times;
              </span>
            </div>

            <div className="modal-body text-center">
              <p>
                Your RRR was generated successfully please copy the RRR Number
              </p>
              <h1>{rrr}</h1>
            </div>
            <div className="modal-footer">
              {loadingmodal ? (
                <button
                  type="button"
                  className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn "
                >
                  Please wait...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn text-uppercase"
                  onClick={_pay}
                >
                  Continue to Payment
                </button>
              )}
            </div>
          </div>
        </div>
      </ReactModal>
      <div className="content-wrapper">
        <div className="row">
          <div className="col-md-12 grid-margin transparent">
            <div className="row">
              <div className="col-md-12 stretch-card transparent">
                <div className="card card-white mt-2">
                  <div className="card-body">
                    <p className="fs-30 mb-4 text-center text-uppercase">
                      pay your application fee
                    </p>
                    {loading ? (
                      <Loader />
                    ) : (
                      <>
                        {data ? (
                          <>
                            <div className="mt-2">
                              <b>Inmportant Notice</b>:<br />
                              Kindly copy your Application ID on a piece of
                              paper as you will need it to make payment on the
                              payment platform
                            </div>
                            <div className="mt-2">
                              Application ID: <b>{selectedApplication?.code}</b>
                            </div>
                            <div className="mt-2 mb-2">
                              Programme:{" "}
                              <b>
                                {courseinfo?.progcode} | {courseinfo?.progname}
                              </b>
                            </div>
                            <div className="mt-3 table-responsive">
                              <TblPayFee data={data} />
                            </div>
                            <div className="mt-3">
                              {selectedapp.ispaid === false ? (
                                <div className="row">
                                  {loadRRR ? (
                                    <div className="col-md-6">
                                      <button className="btn btn-primary btn-block btn-lg font-weight-medium auth-form-btn text-uppercase">
                                        Please wait system generating RRR Code
                                      </button>
                                    </div>
                                  ) : (
                                    <div className="col-md-6">
                                      <button
                                        className="btn btn-primary btn-block btn-lg font-weight-medium auth-form-btn text-uppercase"
                                        onClick={_generateRRR}
                                      >
                                        Generate Payment RRR Code
                                      </button>
                                    </div>
                                  )}
                                  <div className="text-right col-md-6">
                                    <button
                                      onClick={_show}
                                      className=" btn btn-block btn-info btn-lg font-weight-medium auth-form-btn text-uppercase"
                                    >
                                      I have paid for this application
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <button
                                    className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn text-uppercase"
                                    onClick={() => navigate("/uploads")}
                                  >
                                    You have paid for this application continue
                                    to credentials upload
                                  </button>
                                  {/* <div className="text-danger text-center">
                                    Please wait payment gateway on coming soon
                                  </div> */}
                                </>
                              )}
                            </div>
                          </>
                        ) : (
                          <div className="text-gray text-center">
                            No payment items at the moment{" "}
                            <Link to="/dashboard">
                              Click here to return to dashboard
                            </Link>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPayFee;
