import React from "react";
import { contextapi } from "../../helpers/contextapi";
import { axiosget } from "../../helpers/httprequest";
import html2canvas from "html2canvas";
import is_null from "../../helpers/is_null";
import Loader from "../../components/Loader";

const MainPrint = React.forwardRef((props, ref) => {
  const context = React.useContext(contextapi);
  const [loading, setloading] = React.useState(false);
  const [bioinfo, setbioinfo] = React.useState(undefined);
  const [payment, setpayment] = React.useState(undefined);
  const [photo, setphoto] = React.useState(null);
  const [proginfo, setproginfo] = React.useState(undefined);
  const [seminfo, setseminfo] = React.useState(undefined);

  const { selectedApplication: application } = context.values;
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget(`ms_applications/final/${application._id}`);
        if (req.data) {
          const { bio, paid, prog, sem } = req.data;
          setbioinfo(bio);
          setseminfo(sem);
          setproginfo(prog);
          setphoto(bio?.photo?.newname);
          setpayment(paid);
        }
      } catch ({ message }) {
        console.log(message);
      }
    })();
  }, []);

  const _print = async () => {
    setloading(true);
    html2canvas(document.querySelector("#printDocument"), {
      dpi: 144,
      scale: 5,
      useCORS: true,
    }).then(async (canvas) => {
      var t = canvas.toDataURL().replace("data:image/png;base64,", "");
      await downloadBase64File("image/png", t, "image");
      setloading(false);
    });
  };
  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };
  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="row">
          <div className="col-md-12 grid-margin transparent">
            <div className="card">
              <div className="card-body">
                <div className="container-fluid">
                  <div className="p-5" id="printDocument">
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <div>
                          <img
                            src="images/logo.jpg"
                            alt="logo"
                            style={{ maxWidth: 150 }}
                          />
                          <img
                            src="images/buk.jpeg"
                            style={{ maxWidth: 110 }}
                            alt="BUK logo"
                          />
                        </div>
                        <div>
                          ADAMU TAFAWA BALEWA COLLEGE OF EDUCATION KANGERE,
                          BAUCHI STATE
                        </div>
                        <div>APPLICATION SLIP</div>
                        <div>{new Date().toISOString()}</div>
                      </div>
                      <div className="col-md-4">
                        {is_null(photo) ? (
                          <Loader />
                        ) : (
                          <img
                            src={`https://doc.samtec.ng/${photo}`}
                            className="w-100 br-10"
                            alt="passport"
                          />
                        )}
                      </div>
                      <div className="col-md-8">
                        <div className="row my-2 bordered_bottom">
                          <div className="col-md-4 text-muted ">
                            Application ID
                          </div>
                          <div className="col-md-7 ">{application?.code}</div>
                        </div>
                        <div className="row my-2 bordered_bottom">
                          <div className="col-md-4 text-muted ">
                            Programme Details
                          </div>
                          <div className="col-md-7 ">
                            {proginfo?.code} :: {proginfo?.name}
                          </div>
                        </div>
                        <div className="row my-2 bordered_bottom">
                          <div className="col-md-4 text-muted">
                            School Semester
                          </div>
                          <div className="col-md-8">
                            {seminfo?.code} :: {seminfo?.name}
                          </div>
                        </div>
                        <div className="row my-2 bordered_bottom">
                          <div className="col-md-4 text-muted">Full Name</div>
                          <div className="col-md-7">
                            {bioinfo?.firstname} {bioinfo?.middlename}{" "}
                            {bioinfo?.lastname}
                          </div>
                        </div>
                        <div className="row my-2 bordered_bottom">
                          <div className="col-md-4 text-muted">
                            Date of Birth
                          </div>
                          <div className="col-md-7">
                            {bioinfo &&
                              new Date(
                                bioinfo?.dateofbirth
                              ).toLocaleDateString()}
                          </div>
                        </div>

                        <div className="row my-2 bordered_bottom">
                          <div className="col-md-4 text-muted">
                            Phont Number
                          </div>
                          <div className="col-md-8">{bioinfo?.phonenumber}</div>
                        </div>
                        <div className="row my-2 bordered_bottom">
                          <div className="col-md-4 text-muted">
                            Email Address
                          </div>
                          <div className="col-md-7">
                            {bioinfo?.emailaddress}
                          </div>
                        </div>
                        <div className="row my-2 bordered_bottom">
                          <div className="col-md-4 text-muted">Gender</div>
                          <div className="col-md-7">{bioinfo?.gender}</div>
                        </div>
                        <div className="row my-2 bordered_bottom">
                          <div className="col-md-4 text-muted">
                            Payment Reference
                          </div>
                          <div className="col-md-7">{payment?.trxcode}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="my-3">
                      {loading ? (
                        <button className="btn btn-block text-uppercase btn-primary btn-lg font-weight-medium auth-form-btn">
                          Please wait downloading application slip
                        </button>
                      ) : (
                        <button
                          onClick={_print}
                          className="btn btn-block text-uppercase btn-primary btn-lg font-weight-medium auth-form-btn"
                        >
                          Download Application Slip
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default MainPrint;
