import React from "react";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import { axiosget } from "../../helpers/httprequest";
// import is_null from "../../helpers/is_null";
// import converter from "number-to-words";
import "./index.css";
// import is_money from "../../helpers/is_money";

const Letter = () => {
  const { id } = useParams();
  const [bio, setbio] = React.useState();
  const [loading, setloading] = React.useState(true);
  const [error, seterror] = React.useState(0);
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget(`ms_applications/letter/${id}`);
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        const { data } = req.data;
        setbio(data);
        setloading(false);
      } catch ({ message }) {
        seterror(message);
        setloading(false);
      }
    })();
    //eslint-disable-next-line
  }, []);
  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            width: "100vw",
            height: "100vh",
            flexWrap: "wrap",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          {error ? (
            <div>{error}</div>
          ) : (
            <div className="container">
              <div className="header">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <img
                    className="py-2"
                    src="../images/logo.jpg"
                    alt="Intitution Logo"
                    style={{ maxWidth: 120 }}
                  />
                  <div style={{ paddingTop: 10 }}>
                    <h1
                      style={{
                        marginTop: 0,
                        marginBottom: 3,
                        color: "blue",
                        fontSize: 40,
                      }}
                    >
                      ADAMU TAFAWA BALEWA
                    </h1>
                    <h3
                      style={{ marginTop: 0, marginBottom: 3, color: "blue" }}
                    >
                      COLLEGE OF EDUCATION KANGERE, BAUCHI STATE
                    </h3>

                    <div className="text-uppercase">
                      in affliation with Bayero University Kano
                    </div>
                    <div className="text-uppercase">Central Administration</div>
                    <div className="text-uppercase">
                      P.M.B 0316, BAUCHI GOMBE ROAD, KANGERE TOWN, Bauchi State,
                      Nigeria
                    </div>
                  </div>

                  <img
                    className="py-2"
                    src="../images/buk.jpeg"
                    alt="Intitution Logo"
                    style={{ maxWidth: 120 }}
                  />
                </div>
              </div>

              <div className="lettertohead">
                <div className="">
                  <div>
                    <b style={{ color: "red" }}>PROVOST:</b>
                  </div>
                  <b className="text-uppercase">
                    Dr. Buteh DAUDA Sanda{" "}
                    <small style={{ fontSize: 10 }}>MICCON, NCSN</small>
                  </b>
                  <div>HND, MSc, PhD (ATBU)</div>
                  <div>provost@coekangere.edu.ng</div>
                </div>
                <div className="">
                  <div>
                    <b style={{ color: "red" }}>REGISTRAR:</b>
                  </div>
                  <div className="text-uppercase">
                    <b>
                      Alh. Sambo Aliyu,{" "}
                      <small style={{ fontSize: 10 }}>MNIM, FIICA</small>
                    </b>
                  </div>
                  <div>BA (HONS) (BUK)</div>
                  <div>registrar@coekangere.edu.ng</div>
                </div>
              </div>
              <div className="officename">(Office of the registrar)</div>

              <div className="letterto">
                <div className="">
                  <b className="text-uppercase">
                    {bio.firstname} {bio.middlename} {bio.lastname}
                  </b>
                  <br />
                  <span>
                    {bio.state} {bio.lga}
                  </span>{" "}
                  <br />
                  JAMB: <b>{bio.jamb}</b>
                </div>
                <div className="">Date: {new Date().toDateString()}</div>
              </div>

              <div className="body">
                <div className="lettertitle">
                  Offer of{" "}
                  {bio?.status === 1
                    ? "Provisional "
                    : bio.status === 2
                    ? "conditional "
                    : ""}
                  admission
                </div>
                <div style={{ textAlign: "justify" }}>
                  After considering your application for admission into the
                  college, I am pleased to write on behalf of the Academic Board
                  to offer you Admission to study <b>{bio.progname}</b> {` `}
                  in the <b>{bio.department}</b>, {bio.college} in the{" "}
                  {bio.sesname} beginning immediately on the condition that:-
                </div>
                <ul id="letter">
                  <li>
                    <b>Duration of Course: 3 year(s)</b>
                  </li>
                  <li>
                    The particulars provided in your application form are
                    correct in every respect.
                  </li>
                  <li>
                    You will present at the time of Registration the original
                    copies of the credentials listed in your application for
                    scrutiny. SSCE result slip or its equivalent will be
                    accepted in lieu of certificate
                  </li>
                  <li>
                    If at any time, even after your registration it is
                    discovered that you do not satisfy the minimum requirements
                    prescribed for admission for your course of study at the
                    time of this offer, or at that any of the qualifications you
                    purported to have had is false, or incorrectly quoted, or
                    that any other information you provided is false or
                    incorrect, you will be withdrawn from the College.
                  </li>
                  <li>
                    You will abide by all the rules and regulations of the
                    College
                  </li>
                  <li>
                    You will accept any disciplinary action taken against you by
                    the college management if you are found to have contravened
                    any of the rules and regulations of the College
                  </li>
                  <li>
                    The name by which you are hereby admitted and by which you
                    will be registered is the one which will appear in any
                    certificate that the College may issue to you on successful
                    completion of your course
                  </li>
                  <li>
                    This offer is not transferable to another session unless it
                    is approved.
                  </li>
                  <li>
                    The Management may decide to withdraw the admission offered
                    you anytime at its discretion.
                  </li>
                  <li>
                    Your Admission number shall be given to you after screening
                    and payment of approved fees. Please quote this number in
                    any correspondence on this offer between now and time of
                    registration. You are also required to send in an acceptance
                    letter for the offer within two weeks of the date on the
                    admission letter failing which it shall be deemed to have
                    lapsed. No candidate will be attended to, after the closure
                    of the registration exercise.
                  </li>
                  <li>
                    If you accept this offer, you will be required to pay a
                    non-refundable registration fee as per the attached
                    scheduled of fees at the time of registration. You are also
                    required to note that the College shall not enter into
                    negotiation with any Scholarship Board or Government
                    Ministry on behalf of any candidate in respect of the mode
                    of payment of fees.
                  </li>
                  <li>
                    Accommodation will be made available on the payment of
                    appropriate fees on the basis of first come first served.
                    However, the College does not guarantee the availability of
                    accommodation arrangement elsewhere.
                  </li>
                  {/* <li>
                    All admitted candidates will be required to submit a Medical
                    Certificate of fitness duly signed by a Medical Doctor from
                    a recognized Government/Private Hospital before they are
                    registered.
                  </li> */}
                  <li>
                    <b>
                      <u>
                        Candidates should note that this provisional admission
                        is subject to production of JAMB (DE) results for either
                        2021/2022 or 2022/2023 Academic session.
                      </u>
                    </b>
                  </li>
                </ul>
                <div className="text-center">
                  Accept my congratulations.
                  <br />
                  <br />
                  <img src="../images/reg.png" alt="Registrar Signature" />
                  <br />
                  <b>
                    Alh. Sambo Aliyu,{" "}
                    <small style={{ fontSize: 9 }}>
                      MNIM, FIICA (Danlawal of Jama’are)
                    </small>
                    <br />
                    Registrar
                  </b>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Letter;
