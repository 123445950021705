import React from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import { contextapi } from "../../helpers/contextapi";
import { axiosget } from "../../helpers/httprequest";
// import is_money from "../../helpers/is_money";
// import is_null from "../../helpers/is_null";
// import moment from "../../helpers/moment";
// import swal from "../../helpers/swal";
import TblUploads from "../../table/TblUploads";

const MainApplicationMenu = () => {
  const context = React.useContext(contextapi);
  const [dataapplication, setdataapplication] = React.useState(undefined);
  const [loading, setloading] = React.useState(true);
  const [next, setnext] = React.useState(false);
  const navigate = useNavigate();

  //creating function to load ip address from the API
  React.useEffect(() => {
    const { selectedApplication } = context.values;
    (async () => {
      try {
        const req = await axiosget(
          `ms_programme_admission_requirement/${selectedApplication.programme}`
        );
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        setdataapplication(req.data);

        const reqnext = await axiosget(
          `ms_application_uploads/continue/${selectedApplication._id}/${selectedApplication.programme}`
        );
        if (reqnext.data?.type) {
          throw new Error(reqnext.data?.message);
        }
        setnext(reqnext.data.verify);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
  }, []);
  const _continue = () => navigate("/final");
  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="row">
          <div className="col-md-12 grid-margin transparent">
            <div className="row ">
              <div className="col-md-12">
                <div className="card ">
                  <div className="card-body">
                    <>
                      {loading ? (
                        <Loader />
                      ) : dataapplication ? (
                        <TblUploads data={dataapplication} />
                      ) : (
                        "Sorry no required document uploaded"
                      )}
                    </>
                    {next && (
                      <>
                        <button
                          className="mt-5 btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                          onClick={_continue}
                        >
                          CONTINUE TO FINAL SUBMISSION
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainApplicationMenu;
