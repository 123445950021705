import React from "react";

const Ijmb = ({ data, setdata }) => {
  return (
    <div>
      <div className="row">
        <div className="col-md-8">
          <div className="form-group">
            <label htmlFor="firstname">
              Name of Institution <b className="text-danger">*</b>
            </label>
            <input
              className="form-control form-control-lg"
              id="firstname"
              required
              placeholder="Name of Institution"
              onChange={(e) =>
                setdata({ ...data, institution: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="middlename">
              Year of Graduation <b className="text-danger">*</b>
            </label>
            <input
              className="form-control form-control-lg"
              id="middlename"
              placeholder="Year of Graduation"
              onChange={(e) =>
                setdata({ ...data, graduationyear: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <div className="form-group">
            <label htmlFor="firstname">
              Subject 1 <b className="text-danger">*</b>
            </label>
            <input
              className="form-control form-control-lg"
              id="firstname"
              required
              placeholder="Subject 1"
              onChange={(e) => setdata({ ...data, subject1: e.target.value })}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="middlename">
              Subject 1 Grade<b className="text-danger">*</b>
            </label>
            <input
              className="form-control form-control-lg"
              id="middlename"
              placeholder="Subject 1 Grade"
              onChange={(e) =>
                setdata({ ...data, subject1grade: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <div className="form-group">
            <label htmlFor="firstname">
              Subject 2 <b className="text-danger">*</b>
            </label>
            <input
              className="form-control form-control-lg"
              id="firstname"
              required
              placeholder="Subject 2"
              onChange={(e) => setdata({ ...data, subject2: e.target.value })}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="middlename">
              Subject 2 Grade<b className="text-danger">*</b>
            </label>
            <input
              className="form-control form-control-lg"
              id="middlename"
              placeholder="Subject 2 Grade"
              onChange={(e) =>
                setdata({ ...data, subject2score: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <div className="form-group">
            <label htmlFor="firstname">
              Subject 3 <b className="text-danger">*</b>
            </label>
            <input
              className="form-control form-control-lg"
              id="firstname"
              required
              placeholder="Subject 3"
              onChange={(e) => setdata({ ...data, subject3: e.target.value })}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="middlename">
              Subject 3 Grade<b className="text-danger">*</b>
            </label>
            <input
              className="form-control form-control-lg"
              id="middlename"
              placeholder="Subject 3 Grade"
              onChange={(e) =>
                setdata({ ...data, subject3score: e.target.value })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ijmb;
