import React from "react";
import { appHost } from "../../helpers/uri";

const Loader = () => {
  return (
    <div
      style={{
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={`${appHost}/images/loading.gif`}
        alt="loader"
        style={{ maxWidth: 100 }}
      />
      <p>Please wait... system loading</p>
    </div>
  );
};

export default Loader;
