import React from "react";
import DelReferee from "../../buttons/DelReferee";
import moment from "../../helpers/moment";

const TblReferee = ({ data }) => {
  return (
    <div className="table-responsive">
      <table
        id="example"
        className="table table-hover table-striped display expandable-table w-100"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>First Name</th>
            <th>Middle Name</th>
            <th>Last Name</th>
            <th>Phone Number</th>
            <th>Email Address</th>
            <th>Delete</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((v, i) => (
            <tr key={i} title={v.homeaddress}>
              <th>{i + 1}</th>
              <td>{v.firstname}</td>
              <td>{v.middlename}</td>
              <td>{v.lastname}</td>
              <td>{v.phonenumber}</td>
              <td>{v.emailaddress}</td>
              <td>{v.disabled ? <DelReferee modules={v} key={i} /> : "-"}</td>
              <td>{moment(v.createdAt)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TblReferee;
