import React from "react";
import is_null from "../../helpers/is_null";
import { axiospost } from "../../helpers/httprequest";
import swal from "../../helpers/swal";

const ConfirmAcceptance = ({ application, transaction }) => {
  const [loading, setloading] = React.useState(false);
  const _save = async () => {
    try {
      setloading(true);
      const req = await axiospost("ms_acceptance_fee_rrr/verify", {
        application: application[0].value,
        transaction,
      });
      if (req.data?.type !== "success") {
        throw new Error(req.data?.message);
      }
      const { type, message } = req.data;
      swal(type, message, undefined, true);
      return;
    } catch ({ message }) {
      swal("error", message, setloading, false);
    }
  };
  return (
    <button className="btn btn-primary" onClick={_save}>
      {loading ? (
        <>
          <i className="fa fa-refresh fa-spin"></i> System Loading
        </>
      ) : (
        <>
          <i className="fa fa-check"></i> Confirm Payment
        </>
      )}
    </button>
  );
};

export default ConfirmAcceptance;
