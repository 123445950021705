import React from "react";
import ReactModal from "react-modal";
import { axiospost } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";
import Ijmb from "./Ijmb";
import Diploma from "./Diploma";
import NCE from "./NCE";
import { contextapi } from "../../helpers/contextapi";

const BtnAlevel = ({ modules }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [data, setdata] = React.useState("");
  const [qualification, setqualification] = React.useState("");
  const [loading, setloading] = React.useState("");
  const context = React.useContext(contextapi);
  const _save = async () => {
    try {
      setloading(true);
      if (is_null(qualification) || is_null(data)) {
        swal("warning", "Please fill the form properly", setloading);
        return;
      }
      const req = await axiospost("ms_applicant_alevel", {
        type: qualification,
        results: data,
      });
      if (req.status !== 200) {
        throw new Error(
          "Sorry, system could not finish processing your request"
        );
      }
      const { data: result } = req;
      if (result && result.type === "success") {
        const { message, type } = result;
        swal(type, message, setloading, true);
        context.setvalues({ ...context.values, reloadALevel: true });
      } else {
        throw new Error(result.message);
      }
    } catch ({ message }) {
      swal("warning", message, setloading, false);
    }
  };
  const _close = () => {
    setshowmodal(false);
  };
  const _show = () => {
    setshowmodal(true);
  };

  return (
    <div>
      <ReactModal
        isOpen={showmodal}
        onRequestClose={() => setshowmodal(false)}
        className="ReactModal"
      >
        <div className="modal-dialog " role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center">
                Student A-Level Results
              </h5>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="qualification">
                      Select A Level Qualification
                    </label>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setdata({ type: e.target.value });
                        setqualification(e.target.value);
                      }}
                    >
                      <option>---</option>
                      <option value="IJMB">IJMB</option>
                      <option value="NCE">NCE</option>
                      <option value="Diploma">Diploma/Advance Diploma</option>
                    </select>
                  </div>
                </div>
              </div>
              {qualification === "IJMB" && (
                <Ijmb setdata={setdata} data={data} />
              )}
              {qualification === "Diploma" && (
                <Diploma setdata={setdata} data={data} />
              )}
              {qualification === "NCE" && <NCE setdata={setdata} data={data} />}
            </div>
            <div className="modal-footer">
              {loading ? (
                <button type="button" className="btn btn-primary">
                  Please wait...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={_save}
                >
                  Save changes
                </button>
              )}
              <button type="button" className="btn btn-danger" onClick={_close}>
                Close
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
      <div>
        {!modules && (
          <div
            style={{
              position: "fixed",
              right: "50px",
              bottom: "50px",
            }}
          >
            <button
              className="btn btn-icon btn-primary btn-rounded"
              onClick={_show}
            >
              <i className="ti-plus"></i>
            </button>
          </div>
        )}
      </div>
      <div>
        {modules && (
          <button
            className="btn btn-icon btn-primary btn-rounded"
            onClick={_show}
          >
            <i className="ti-pencil"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default BtnAlevel;
