import React from "react";
import UploadDocument from "../../buttons/UploadDocument";
import LoadUploads from "../../components/LoadUploads";

const TblUploads = ({ data }) => {
  return (
    <div className="table-responsive">
      <table
        id="example"
        className="table table-hover table-striped display expandable-table w-100"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Programme</th>
            <th>Document Title</th>
            <th>Is Document Optional?</th>
            <th>Document Uploaded</th>
            <th>Upload Document</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((v, i) => (
            <tr key={i}>
              <th>{i + 1}</th>
              <td>{v.name}</td>
              <td>{v.label}</td>
              <td>{v.required ? "No" : "Yes"}</td>
              <td>
                <LoadUploads requirement={v._id} />
              </td>
              <td>
                <UploadDocument modules={v} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TblUploads;
