import React from "react";
import { Link } from "react-router-dom";
import StartApplication from "../../components/StartApplication";
// import { contextapi } from "../../helpers/contextapi";
import { axiosget } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import Loader from "../../components/Loader";

const MainNewApplication = () => {
  // const context = React.useContext(contextapi);
  const [showerror, setshowerror] = React.useState(false);
  const errormessage = (
    <div className="text-center">
      Sorry, you have to add
      <ol>
        <li>
          Parent/Guardian{" "}
          <Link to="/parents">click here to add the two Parent/Guardian</Link>
        </li>
        <li>
          Bio Data{" "}
          <Link to="/bio">click here to add the two Parent/Guardian</Link>
        </li>
      </ol>
      for you to continue with your application
    </div>
  );
  const [loading, setloading] = React.useState(false);
  React.useEffect(() => {
    (async () => {
      try {
        setloading(true);
        const req = await axiosget("ms_applicant_referee/my");
        if (req.data && req.data.length !== 2) {
          setshowerror(true);
        }
        setloading(false);
      } catch ({ message }) {
        console.warn(message);
      }
    })();
  }, []);

  return (
    <div className="main-panel">
      {loading ? (
        <Loader />
      ) : (
        <div className="content-wrapper">
          <div className="row">
            <div className="col-md-12 grid-margin transparent">
              <div className="row">
                <div className="col-md-12 stretch-card transparent">
                  <div className="card card-white">
                    <div className="card-body">
                      <p className="fs-30 mb-4 text-center text-uppercase">
                        Start New Application
                      </p>
                      {(is_null(showerror) || showerror) && errormessage}
                      {!showerror && <StartApplication />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainNewApplication;
