import React from "react";
import { Link } from "react-router-dom";

const Sidebar = () => {
  return (
    <nav className="sidebar sidebar-offcanvas " id="sidebar">
      <ul className="nav">
        <li className="nav-item">
          <Link className="nav-link" to="/dashboard">
            <i className="icon-grid menu-icon"></i>
            <span className="menu-title">Dashboard</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/parents">
            <i className="icon-square-check menu-icon"></i>
            <span className="menu-title">Parent & Guardian</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/bio">
            <i className="icon-head menu-icon"></i>
            <span className="menu-title">Bio Data</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/olevel">
            <i className="icon-menu menu-icon"></i>
            <span className="menu-title">O-Level Results</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/alevel">
            <i className="icon-share menu-icon"></i>
            <span className="menu-title">A-Level Results</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/start">
            <i className="icon-plus menu-icon"></i>
            <span className="menu-title">New Application</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/admission">
            <i className="icon-printer menu-icon"></i>
            <span className="menu-title">Admission Letter</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/receipt">
            <i className="icon-paper menu-icon"></i>
            <span className="menu-title">Payment Receipt</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/accept">
            <i className="icon-marquee-plus menu-icon"></i>
            <span className="menu-title">Admission Acceptance</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/">
            <i className="icon-lock menu-icon"></i>
            <span className="menu-title">Sign Out</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;
