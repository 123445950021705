import React from "react";
import { useNavigate } from "react-router-dom";
import { contextapi } from "../../helpers/contextapi";
import { axiosget } from "../../helpers/httprequest";
import is_money from "../../helpers/is_money";
import swal from "../../helpers/swal";

const MainFinal = () => {
  const context = React.useContext(contextapi);
  const [payment, setpayment] = React.useState(undefined);
  const [bioinfo, setbioinfo] = React.useState(undefined);
  const [documents, setdocuments] = React.useState(undefined);
  const [refereeinfo, setrefereeinfo] = React.useState(undefined);
  const [proginfo, setproginfo] = React.useState(undefined);
  const [seminfo, setseminfo] = React.useState(undefined);
  const [loading, setloading] = React.useState(undefined);
  const [attest, setattest] = React.useState(false);
  const navigate = useNavigate();
  const { selectedApplication: application } = context.values;
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget(`ms_applications/final/${application._id}`);
        if (req.data) {
          const { bio, referee, paid, docs, prog, sem } = req.data;
          setbioinfo(bio);
          setrefereeinfo(referee);
          setpayment(paid);
          setdocuments(docs);
          setproginfo(prog);
          setseminfo(sem);
        }
      } catch ({ message }) {
        console.log(message);
      }
    })();
  }, []);
  const { user } = context.values;
  const _complete = async () => {
    setloading(true);
    try {
      const req = await axiosget(`ms_applications/complete/${application._id}`);
      if (req.status !== 200) {
        throw new Error("Sorry, something went wrong, Please try again");
      }
      const { type, message } = req.data;
      if (type === "success") {
        swal(type, message, () => navigate("/print"), false);
        return;
      }
      swal(type, message, setloading, false);
    } catch ({ message }) {
      swal("error", message, setloading, false);
    }
  };
  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="row">
          <div className="col-md-12 grid-margin">
            <div className="row">
              <div className="col-12 col-xl-8 mb-4 mb-xl-0">
                <h3 className="font-weight-bold">Welcome {user?.firstname}</h3>
                <h6 className="font-weight-normal mb-0">
                  Available applications are dark blue, while applications that
                  have expired red, completed applications are marked green
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin transparent">
            <div className="card">
              <div className="card-body">
                <div className="container-fluid">
                  <div className="fs-30">BIO INFORMATION</div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="pr-5">
                        <img
                          src={bioinfo?.photo.link}
                          className="w-100 br-10"
                          alt="passport"
                        />
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="row bordered_bottom">
                        <div className="col-md-4 text-muted ">
                          Application ID
                        </div>
                        <div className="col-md-8 ">{application?.code}</div>
                      </div>
                      <div className="row my-2 bordered_bottom">
                        <div className="col-md-4 text-muted ">
                          Programme Details
                        </div>
                        <div className="col-md-8 ">
                          {proginfo?.code} :: {proginfo?.name}
                        </div>
                      </div>
                      <div className="row my-2 bordered_bottom">
                        <div className="col-md-4 text-muted">
                          School Semester
                        </div>
                        <div className="col-md-8">
                          {seminfo?.code} :: {seminfo?.name}
                        </div>
                      </div>
                      <div className="row my-2 bordered_bottom">
                        <div className="col-md-4 text-muted">Full Name</div>
                        <div className="col-md-8">
                          {bioinfo?.firstname} {bioinfo?.middlename}{" "}
                          {bioinfo?.lastname}
                        </div>
                      </div>
                      <div className="row my-2 bordered_bottom">
                        <div className="col-md-4 text-muted">Date of Birth</div>
                        <div className="col-md-8">
                          {bioinfo &&
                            new Date(bioinfo?.dateofbirth).toLocaleDateString()}
                        </div>
                      </div>

                      <div className="row my-2 bordered_bottom">
                        <div className="col-md-4 text-muted">Phont Number</div>
                        <div className="col-md-8">{bioinfo?.phonenumber}</div>
                      </div>
                      <div className="row my-2 bordered_bottom">
                        <div className="col-md-4 text-muted">Email Address</div>
                        <div className="col-md-8">{bioinfo?.emailaddress}</div>
                      </div>
                      <div className="row my-2 bordered_bottom">
                        <div className="col-md-4 text-muted">Religion</div>
                        <div className="col-md-8">{bioinfo?.religion}</div>
                      </div>
                      <div className="row my-2 bordered_bottom">
                        <div className="col-md-4 text-muted">Gender</div>
                        <div className="col-md-8">{bioinfo?.gender}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row my-2 bordered_bottom">
                        <div className="col-md-4 text-muted">
                          Marital Status
                        </div>
                        <div className="col-md-8">{bioinfo?.maritalstatus}</div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row my-2 bordered_bottom">
                        <div className="col-md-4 text-muted">Qualification</div>
                        <div className="col-md-8">{bioinfo?.qualification}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row my-2 bordered_bottom">
                        <div className="col-md-4 text-muted">
                          State of Origin
                        </div>
                        <div className="col-md-8">{bioinfo?.stateoforigin}</div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row my-2 bordered_bottom">
                        <div className="col-md-4 text-muted">LGA of Origin</div>
                        <div className="col-md-8">{bioinfo?.lgaoforigin}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row my-2 bordered_bottom">
                        <div className="col-md-4 text-muted">
                          State of Residence
                        </div>
                        <div className="col-md-8">
                          {bioinfo?.stateofresidence}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row my-2 bordered_bottom">
                        <div className="col-md-4 text-muted">
                          LGA of Residence
                        </div>
                        <div className="col-md-8">
                          {bioinfo?.lgaofresidence}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row bordered_bottom">
                    <div className="col-md-4 text-muted">Home Address</div>
                    <div className="col-md-4">{bioinfo?.homeaddress}'s</div>
                  </div>
                  <div className="fs-30">GUARDIAN INFORMATION</div>
                  <div className="row">
                    {refereeinfo &&
                      refereeinfo?.map((v) => (
                        <div className="col-md-6 ">
                          <div className="row my-2 bordered_bottom">
                            <div className="col-md-4 text-muted">Full Name</div>
                            <div className="col-md-8">{`${v.firstname} ${v.middlename} ${v.lastname}`}</div>
                          </div>
                          <div className="row my-2 bordered_bottom">
                            <div className="col-md-4 text-muted">
                              Phont Number
                            </div>
                            <div className="col-md-6">{v.phonenumber}</div>
                          </div>
                          <div className="row my-2 bordered_bottom">
                            <div className="col-md-4 text-muted">
                              Email Address
                            </div>
                            <div className="col-md-6">{v.emailaddress}</div>
                          </div>
                          <div className="row my-2 bordered_bottom">
                            <div className="col-md-4 text-muted">
                              Home address
                            </div>
                            <div className="col-md-6">{v.homeaddress}</div>
                          </div>
                        </div>
                      ))}
                  </div>

                  <div className="fs-30">PAYMENT INFORMATION</div>
                  <div className="row">
                    <div className="col-md-6 my-2 bordered_bottom">
                      <div className="row">
                        <div className="col-md-4 text-muted">
                          Reference Number
                        </div>
                        <div className="col-md-8">{payment?.trxcode}</div>
                      </div>
                    </div>
                    <div className="col-md-6 my-2 bordered_bottom">
                      <div className="row">
                        <div className="col-md-4 text-muted">Amount Paid</div>
                        <div className="col-md-8">
                          {is_money(payment?.amount)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="fs-30">DOCUMENT(S) UPLOADED</div>
                  <div className="row">
                    {documents &&
                      documents.map((v, i) => (
                        <div key={i} className="col-md-12 my-2 bordered_bottom">
                          <div className="text-muted text-uppercase ">
                            {v.label}
                          </div>
                          <img
                            src={v.uri.link}
                            alt={v.label}
                            className="uploadedDocument"
                          />
                        </div>
                      ))}
                  </div>
                  <div className="col-md-12 my-5">
                    <label className="fs-20">
                      <input
                        type="checkbox"
                        onChange={() => setattest(!attest)}
                      />
                      I hereby, attest that the data provided is genuine to the
                      best of my knowledge and all certificates attached to this
                      application are authentic,{" "}
                      <span className="text-danger">
                        At any point in my academic study, if the college
                        discovered any of the provided document is not
                        authentic, I consent to the college withdrawing my
                        admission. Thereby rendering my progress null and void.
                      </span>
                    </label>
                    <div className="text-center mt-2">
                      tap/click on the above attestation to see the final submit
                      button
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        {attest && !application.iscompleted && (
                          <>
                            {loading ? (
                              <button className="btn btn-block text-uppercase btn-primary btn-lg font-weight-medium auth-form-btn">
                                Please wait...
                              </button>
                            ) : (
                              <button
                                className="btn btn-block text-uppercase btn-primary btn-lg font-weight-medium auth-form-btn"
                                onClick={_complete}
                              >
                                Complete My Application
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainFinal;
