import React from "react";
import { useNavigate } from "react-router-dom";
import { contextapi } from "../../helpers/contextapi";
import moment from "../../helpers/moment";
import swal from "../../helpers/swal";

const ContinueApplication = ({ data }) => {
  const navigate = useNavigate();
  const context = React.useContext(contextapi);
  const _picked_card = () => {
    if (!data?.sessionstatus) {
      swal("error", "Sorry, this application has expire", undefined, false);
    }
    localStorage.setItem("applicationId", JSON.stringify(data));
    context.setvalues({ ...context.values, selectedApplication: data });
    if (data?.iscompleted) {
      navigate("/print");
    } else {
      if (!data?.ispaid) {
        navigate("/pay");
      } else {
        navigate("/uploads");
      }
    }
  };
  return (
    <div className="col-md-12 stretch-card transparent mt-4 applicant_menu">
      <div
        className={`card card-dark-${
          !data?.sessionstatus ? "red" : !data.iscompleted ? "blue" : "green"
        }`}
      >
        <div className="card-body" onClick={_picked_card}>
          <div className="row">
            <div className="col-md-4">
              <span className="applicant_menu_span">{data?.progcode}</span>
            </div>
            <div className="col-md-8 pt-4">
              <p>{data?.progname}</p>
              <p>{data?.code}</p>
              <p>Application Fee {data?.ispaid ? "Paid" : "Not Paid"}</p>
              <p>Started Application {moment(data?.createdAt)} ago</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContinueApplication;
