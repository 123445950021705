import React from "react";
import { useNavigate } from "react-router-dom";
import { contextapi } from "../../helpers/contextapi";
import { axiosget, axiospost } from "../../helpers/httprequest";
import is_money from "../../helpers/is_money";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";
import Loader from "../Loader";
import SelectStatus from "../SelectStatus";

const StartApplication = () => {
  const [list, setlist] = React.useState(undefined);
  const context = React.useContext(contextapi);
  const [programmedata, setprogrammedata] = React.useState(undefined);
  const [costdata, setcostdata] = React.useState(undefined);
  const [uploadsdata, setuploadsdata] = React.useState(undefined);
  const [applicationtype, setapplicationtype] = React.useState(undefined);
  const [laodingreq, setlaodingreq] = React.useState(undefined);
  const [selectprogramme, setselectprogramme] = React.useState(undefined);
  const [loading, setloading] = React.useState(false);
  const [olevel, setolevel] = React.useState(null);

  const navigate = useNavigate();

  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget("ms_programme/active");
        if (req?.data?.type) {
          throw new Error(req.data.message);
        }
        const ls = req.data?.map((r) => ({ name: r.name, value: r._id }));
        setlist(ls);

        const reqs = await axiosget(`ms_applicant_sitting`);
        if (reqs.data?.type) {
          throw new Error(reqs.data.message);
        }
        const { data } = reqs;
        if (!is_null(data)) {
          setolevel(data);
        }
      } catch ({ message }) {
        console.warn(message);
      }
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      try {
        if (!is_null(selectprogramme)) {
          setlaodingreq(true);
          const req = await axiosget(
            `ms_applications/requirement/${selectprogramme}`
          );
          if (req?.data?.type) {
            throw new Error(req.data.message);
          }
          const { programme, cost, uploads } = req.data;
          setprogrammedata(programme);
          setcostdata(cost);
          setuploadsdata(uploads);
        }
      } catch ({ message }) {
        console.warn(message);
      }
      setlaodingreq(false);
    })();
  }, [selectprogramme]);

  const _continue = async () => {
    try {
      setloading(true);
      if (is_null(selectprogramme) || is_null(applicationtype)) {
        swal("error", "Please fill the form properly", setloading);
        return;
      }
      const req = await axiospost("ms_applications", {
        programme: selectprogramme,
        type: applicationtype,
      });

      if (req.status !== 200)
        throw new Error("Sorry something went wrong, please try again");
      const { type, message, id } = req.data;
      if (type === "success") {
        localStorage.setItem("applicationId", JSON.stringify(id));
        context.setvalues({ ...context.values, selectedApplication: id });
        swal(type, message, () => navigate(`/pay`), false);
        return;
      }
      swal(type, message, setloading, false);
    } catch ({ message }) {
      swal("error", message, setloading);
    }
  };
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <SelectStatus
            options={list}
            callback={setselectprogramme}
            label="Select the programme you wish to apply into"
          />
        </div>
        <div className="col-md-6">
          <SelectStatus
            options={[
              { name: "Fresh", value: "fresh" },
              { name: "Transfer", value: "de" },
              { name: "Direct Entry", value: "direct entry" },
            ]}
            callback={setapplicationtype}
            label="Select Application Type"
          />
        </div>
      </div>
      <div className="table-responsive">
        {selectprogramme && (
          <>
            {laodingreq ? (
              <Loader />
            ) : (
              <>
                <p>Greetings</p>
                <p>
                  Thank you for attempting to apply for admission to study{" "}
                  <b>{!is_null(programmedata) && programmedata.name}</b> at{" "}
                  <b>
                    ADAMU TAFAWA BALEWA COLLEGE OF EDUCATION KANGERE
                    [DIRECTORATE OF UNDERGRADUATE STUDIES]
                  </b>
                  , to continue with this application, kindly have the soft copy
                  of the following document and your ATM card in close proximity
                  to you:
                </p>
                {!is_null(olevel) ? (
                  <>
                    <div className="my-4 table-responsive">
                      DOCUMENT UPLOAD REQUIREMENT
                      <table
                        id="example"
                        className="table table-hover table-striped display expandable-table w-100"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Item Required</th>
                            <th>Is Optional?</th>
                          </tr>
                        </thead>
                        <tbody>
                          {uploadsdata?.map((v, i) => (
                            <tr key={i}>
                              <th>{i + 1}</th>
                              <td>{v.label}</td>
                              <td>{v.required ? "No" : "Yes"}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="my-4 table-responsive">
                      APPLICATION FEE ITEM(S)
                      <table
                        id="example"
                        className="table table-hover table-striped display expandable-table w-100"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Payment Item</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {costdata?.map((v, i) => (
                            <tr key={i}>
                              <th>{i + 1}</th>
                              <td>{v.name}</td>
                              <td>{is_money(v.amount)}</td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td></td>
                            <td>Total</td>
                            <td>
                              {is_money(
                                costdata
                                  ?.map((r) => r.amount)
                                  .reduce((p, a) => p + a, 0)
                              )}
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    {costdata?.map((r) => r.amount).reduce((p, a) => p + a, 0) >
                      0 && (
                      <>
                        <div className="mt-3">
                          {!loading && (
                            <button
                              className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                              onClick={_continue}
                            >
                              SAVE & CONTINUE TO MAKE PAYMENT
                            </button>
                          )}
                          {loading && (
                            <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">
                              LOADING...
                            </button>
                          )}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <p>Please submit your olevel result to continue</p>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default StartApplication;
