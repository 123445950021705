import React from "react";
// import DelReferee from "../../buttons/DelReferee";
import is_money from "../../helpers/is_money";

const TblReceipt = ({ data }) => {
  console.log(data);
  return (
    <table
      id="example"
      className="table table-hover table-striped display expandable-table w-100"
    >
      <thead>
        <tr>
          <th>#</th>
          <th>Application Code</th>
          <th>Application Type</th>
          <th>Trx. RRR</th>
          <th>Trx. Status</th>
          <th>Trx. ID</th>
          <th>Amount</th>
          <th>Receipt</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((v, i) => (
          <tr key={i}>
            <th>{i + 1}</th>
            <td>{v?.result?.code}</td>
            <td>{String(v?.result?.type).toLocaleUpperCase()}</td>
            <td>{v?.trxcode}</td>
            <td>{v?.trxstatus}</td>
            <td>{v?.verified?.orderId}</td>
            <td>{is_money(v.amount)}</td>
            <td>-</td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th></th>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>Total</td>
          <th>
            {is_money(data?.map((v) => v.amount).reduce((p, a) => p + a, 0))}
          </th>
          <td></td>
        </tr>
      </tfoot>
    </table>
  );
};

export default TblReceipt;
