import React from "react";
import BioDataForm from "../../components/BioDataForm";

const MainBioData = () => {
  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="row">
          <div className="col-md-12 grid-margin transparent">
            <div className="row">
              <div className="col-md-12 stretch-card transparent">
                <div className="card card-white">
                  <div className="card-body">
                    <p className="fs-30 mb-4 text-center text-uppercase">
                      Applicant Bio Data
                    </p>
                    <BioDataForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainBioData;
