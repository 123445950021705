import React from "react";
import Swal from "sweetalert2";
import { axiosdelete } from "../../helpers/httprequest";
import swal from "../../helpers/swal";

const DeleteALevel = ({ id }) => {
  const _delete = async () => {
    Swal.fire({
      icon: "warning",
      title: "DELETE A-LEVEL",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: `Delete`,
      cancelButtonText: `Cancel`,
    }).then(async (r) => {
      if (r.isConfirmed) {
        const req = await axiosdelete(`ms_applicant_alevel/${id}`);
        if (req.status !== 200) {
          throw new Error(
            "Sorry, system could not finish processing your request"
          );
        }
        const { data: result } = req;
        if (result && result.type === "success") {
          const { message, type } = result;
          swal(type, message, undefined, true);
        } else {
          throw new Error(result.message);
        }
      }
    });
  };
  return (
    <div style={{ cursor: "pointer", color: "red" }} onClick={_delete}>
      <i className="icon-trash"></i>
    </div>
  );
};

export default DeleteALevel;
