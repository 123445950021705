import React from "react";
import ShowSittingForm from "../../components/ShowSittingForm";
import ExaminationResults from "../../components/ExaminationResults";
import { contextapi } from "../../helpers/contextapi";
import swal from "../../helpers/swal";
import is_null from "../../helpers/is_null";
import Loader from "../../components/Loader";
import { axiosget, axiospost } from "../../helpers/httprequest";

const MainOLevel = () => {
  const [sitting, setsitting] = React.useState(0);
  const [loading, setloading] = React.useState(0);
  const [wait, setwait] = React.useState(true);
  const context = React.useContext(contextapi);
  const { results, examinsitting, expectedsitting } = context.values;
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget(`ms_applicant_sitting`);
        if (req.data?.type) {
          throw new Error(req.data.message);
        }
        const { data } = req;
        if (!is_null(data)) {
          const { sittings, results } = req.data;

          const sort = results.sort((a, b) => a.sn - b.sn);
          const sorts = sittings.sort((a, b) => a.sit - b.sit);
          const sej = sittings?.map((r) => ({ sit: r.sit, value: r.type }));
          context.setvalues({
            ...context.values,
            results: sort,
            sej,
            expectedsitting: sittings?.length,
            examinsitting: sorts,
          });
        }
        setwait(false);
      } catch ({ message }) {
        setwait(false);
      }
    })();
  }, []);
  const _save = async () => {
    try {
      setloading(true);
      if (is_null(expectedsitting) || is_null(examinsitting)) {
        throw new Error(
          `Sorry, you must selected examination sitting and enter the needed data`
        );
      }
      if (Number(expectedsitting) !== examinsitting?.length) {
        throw new Error(
          `Sorry system expected ${Number(expectedsitting)}, 
          so far ${examinsitting.length} were entered, 
          please make sure you fill all the form data`
        );
      }
      if (is_null(results)) {
        throw new Error("Sorry you must enter the result before you can save");
      }
      if (!results?.length || results?.length < 6) {
        throw new Error(
          `Sorry, system expectes at least 6 results entries, ${results.length} given`
        );
      }
      const req = await axiospost("ms_applicant_sitting", {
        results: results,
        sittings: examinsitting,
      });
      if (req.status !== 200) {
        throw new Error(
          "Sorry, system could not finish processing your request"
        );
      }
      const { data } = req;
      if (data && data.type === "success") {
        const { message, type } = data;
        swal(type, message, setloading, true);
      } else {
        throw new Error(data.message);
      }
    } catch ({ message }) {
      swal("error", message, setloading, false);
    }
  };
  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="row">
          <div className="col-md-12 grid-margin transparent">
            <div className="row">
              <div className="col-md-12 stretch-card transparent">
                <div className="card card-white">
                  <div className="card-body">
                    <p className="fs-30 mb-4 text-center text-uppercase">
                      Student O-Level Results
                    </p>
                    {wait ? (
                      <Loader />
                    ) : (
                      <>
                        <div className="row">
                          <div className="col-md-5">
                            <div>RESULT SITTINGS</div>
                            <div className="form-group">
                              <label htmlFor="nosit">Number of Sittings</label>
                              <select
                                id="nosit"
                                onChange={(e) => {
                                  context.setvalues({
                                    ...context.values,
                                    expectedsitting: e.target.value,
                                  });
                                  setsitting(e.target.value);
                                }}
                                className="form-control"
                              >
                                <option>Number of Sittings</option>
                                <option value={1}>1 Sitting</option>
                                <option value={2}>2 Sittings</option>
                              </select>
                            </div>

                            <div>
                              {sitting ? (
                                <>
                                  {[...Array(Number(sitting))].map((e, i) => {
                                    return (
                                      <div className="my-1 bordered_bottom">
                                        <ShowSittingForm
                                          key={`sittin_loop_${i}`}
                                          sit={1 + i}
                                        />
                                      </div>
                                    );
                                  })}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-md-7">
                            <div>RESULTS</div>
                            {[...Array(Number(9))].map((e, i) => {
                              return (
                                <div
                                  className="bordered_bottom my-1"
                                  key={`o_level_key_${i}`}
                                >
                                  <ExaminationResults
                                    key={`sittin_loop_key_${i}`}
                                    sn={i + 1}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div className="row mt-5">
                          <div className="col-md-12">
                            <button
                              onClick={_save}
                              className="btn btn-large btn-primary btn-block w-100"
                            >
                              {!loading ? `Save` : `Please wait...`}
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainOLevel;
