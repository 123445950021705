import React from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import MainALevel from "../../content/MainALevel";
import BtnAlevel from "../../buttons/BtnALevel";

const ALevel = () => {
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <MainALevel />
      </div>
      <BtnAlevel />
    </div>
  );
};

export default ALevel;
