import React from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import MainBioData from "../../content/MainBioData";

const BioData = () => {
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <MainBioData />
      </div>
    </div>
  );
};

export default BioData;
