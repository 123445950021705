import React from "react";
import { axiospost } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";

const BtnVerify = () => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [name, setname] = React.useState(null);
  const [loading, setloading] = React.useState(false);
  const navigate = useNavigate();
  const _save = async () => {
    try {
      setloading(true);
      if (is_null(name)) {
        swal("error", "Please fill the form properly", setloading);
        return;
      }
      const req = await axiospost(`ms_applicant/reverify`, {
        emailaddress: name,
      });
      if (req.status !== 200)
        throw new Error("Sorry something went wrong, please try again");
      const { type, message } = req.data;
      swal(type, message, setloading, false);
      if (type === "success") {
        _close();
        navigate("/");
      }
    } catch ({ message }) {
      swal("error", message, setloading, false);
    }
  };
  const _close = () => {
    setshowmodal(false);
    setname("");
  };
  const _show = () => {
    setshowmodal(true);
  };

  return (
    <div>
      <Modal
        isOpen={showmodal}
        onRequestClose={() => setshowmodal(false)}
        className="ReactModal"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center text-primary">
                SEND VERIFICATION EMAIL
              </h5>
            </div>
            <div className="modal-body">
              <div className="text-wrap my-4 text-md">
                ENTER REGISTRATION EMAIL ADDRESS
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control form-control-lg"
                  id="exampleInputEmail2"
                  placeholder="Enter Registration Email Address"
                  onChange={(e) => setname(e.target.value)}
                />
              </div>
              <div className="modal-footer">
                {loading ? (
                  <button type="button" className="btn btn-primary">
                    Please wait...
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={_save}
                  >
                    Send Verification
                  </button>
                )}

                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => setshowmodal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div>
        <button onClick={_show} className="btn btn-link">
          I have not received the verification code
        </button>
      </div>
    </div>
  );
};

export default BtnVerify;
