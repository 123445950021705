import React from "react";
import { contextapi } from "../../helpers/contextapi";
import is_null from "../../helpers/is_null";
import score_converter from "../../helpers/score_converter";

const ExaminationResults = ({ sn }) => {
  const [type, settype] = React.useState(null);
  const [subject, setsubject] = React.useState(null);
  const [score, setscore] = React.useState(null);
  const context = React.useContext(contextapi);
  const { sej, results } = context.values;
  React.useEffect(() => {
    if (subject !== null && score !== null && type !== null) {
      if (results === undefined) {
        context.setvalues({
          ...context.values,
          results: [
            { sn, subject, score, scorevalue: score_converter(score), type },
          ],
        });
      } else {
        const filters = results.filter((r, i) => r.sn !== sn);
        filters.push({
          sn,
          subject,
          score,
          scorevalue: score_converter(score),
          type,
        });
        const sort = filters.sort((a, b) => a.sn - b.sn);
        context.setvalues({
          ...context.values,
          results: sort,
        });
      }
    }
  }, [subject, score, type]);
  React.useEffect(() => {
    (async () => {
      try {
        if (!is_null(results)) {
          const data = results[sn - 1];
          setsubject(data?.subject);
          settype(data?.type);
          setscore(data?.score);
        }
      } catch ({ message }) {}
    })();
  }, []);
  return (
    <div className="row w-100">
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="result_type">
            Exam Type {sn <= 7 && <span className="text-danger">*</span>}
          </label>
          <select
            id="result_type"
            onChange={(e) => settype(e.target.value)}
            data="0"
            className="form-control"
          >
            <option value="">--</option>
            {!is_null(sej) &&
              sej.map((v, i) => (
                <option key={`exams_type_id_${i}`} value={v.value}>
                  {v.value}
                </option>
              ))}
          </select>
          <small>{type && `${type} Selected`}</small>
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="result_subject">
            Subject {sn <= 7 && <span className="text-danger">*</span>}
          </label>
          <select
            id="result_subject"
            onChange={(e) => setsubject(e.target.value)}
            data="0"
            className="form-control"
          >
            <option value="">--</option>
            <option value="Additional General Science">
              Additional General Science
            </option>
            <option value="Additional Mathematics">
              Additional Mathematics
            </option>
            <option value="Agricultural Science">Agricultural Science</option>
            <option value="Animal Husbandry">Animal Husbandry</option>
            <option value="Applied Mathematics">Applied Mathematics</option>
            <option value="Arabic Language">Arabic Language</option>
            <option value="Biology">Biology</option>
            <option value="Botany">Botany</option>
            <option value="Chemistry">Chemistry</option>
            <option value="CRS/IRS">CRS/IRS</option>
            <option value="Commerce">Commerce</option>
            <option value="Civic Education">Civic Education</option>
            <option value="Computer Studies">Computer Studies</option>
            <option value="Data Processing">Data Processing</option>
            <option value="English Language">English Language</option>
            <option value="Economics">Economics</option>
            <option value="Food Economics">Food Economics</option>
            <option value="Food Management">Food Management</option>
            <option value="Food Science">Food Science</option>
            <option value="Food and Nutrition">Food and Nutrition</option>
            <option value="Further Mathematics">Further Mathematics</option>
            <option value="Fine and Applied Arts">Fine and Applied Arts</option>
            <option value="Financial Accounting">Financial Accounting</option>
            <option value="French">French</option>
            <option value="General Biology">General Biology</option>
            <option value="General Education">General Education</option>
            <option value="General Science">General Science</option>
            <option value="Government">Government</option>
            <option value="Geography">Geography</option>
            <option value="Geology">Geology</option>
            <option value="Geography">Geography</option>
            <option value="Hausa Language">Hausa Language</option>
            <option value="Health Science">Health Science</option>
            <option value="Human Biology">Human Biology</option>
            <option value="Hygiene">Hygiene</option>
            <option value="History">History</option>
            <option value="Integrated Science">Integrated Science</option>
            <option value="Igbo">Igbo</option>
            <option value="Islamic History">Islamic History</option>
            <option value="Literature in English">Literature in English</option>
            <option value="Mathematics">Mathematics</option>
            <option value="Music">Music</option>
            <option value="Marketing">Marketing</option>
            <option value="Other Nigerian Languages">
              Other Nigerian Languages
            </option>
            <option value="Physics">Physics</option>
            <option value="Pure and Applied Mathematics">
              Pure and Applied Mathematics
            </option>
            <option value="Principles of Accounts">
              Principles of Accounts
            </option>
            <option value="Qua'anic Studies">Qua'anic Studies</option>
            <option value="Rural Biology">Rural Biology</option>
            <option value="Rural Science">Rural Science</option>
            <option value="Social Studies">Social Studies</option>
            <option value="Statistics">Statistics</option>
            <option value="Tahfeezul Qur'an">Tahfeezul Qur'an</option>
            <option value="Teaching Practice">Teaching Practice</option>
            <option value="Ulumul Qur'an">Ulumul Qur'an</option>
            <option value="Zoology">Zoology</option>
          </select>
          <small>{type && `${subject} Selected`}</small>
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="result_score">
            Grade {sn <= 7 && <span className="text-danger">*</span>}
          </label>
          <select
            id="result_score"
            onChange={(e) => setscore(e.target.value)}
            data="0"
            className="form-control"
          >
            <option value="">--</option>
            <option value="A1">A1</option>
            <option value="B2">B2</option>
            <option value="B3">B3</option>
            <option value="C4">C4</option>
            <option value="C5">C5</option>
            <option value="C6">C6</option>
            <option value="D7">D7</option>
            <option value="E8">E8</option>
            <option value="F9">F9</option>
          </select>
          <small> {score && `${score} Selected`}</small>
        </div>
      </div>
    </div>
  );
};

export default ExaminationResults;
