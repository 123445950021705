import React from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import MainPrint from "../../content/MainPrint";
const Print = () => {
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <MainPrint />
      </div>
    </div>
  );
};

export default Print;
