import React from "react";
import { contextapi } from "../../helpers/contextapi";
import { axiosget, axiospost } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";
import SelectStatus from "../SelectStatus";
import states_lga from "./state_lga.json";
import axios from "axios";
import image_processor from "../../helpers/image_processor";
import Loader from "../Loader";

const BioDataForm = () => {
  const context = React.useContext(contextapi);

  const [photo, setphoto] = React.useState(undefined);
  const [firstname, setfirstname] = React.useState("");
  const [middlename, setmiddlename] = React.useState("");
  const [lastname, setlastname] = React.useState("");
  const [gender, setgender] = React.useState("");
  const [religion, setreligion] = React.useState("");
  const [display, setdisplay] = React.useState("");
  const [qualification, setqualification] = React.useState("");
  const [maritalstatus, setmaritalstatus] = React.useState("");
  const [emailaddress, setemailaddress] = React.useState("");
  const [phonenumber, setphonenumber] = React.useState("");
  const [homeaddress, sethomeaddress] = React.useState("");
  const [stateoforigin, setstateoforigin] = React.useState(undefined);
  const [stateofresidence, setstateofresidence] = React.useState(undefined);
  const [lgaoforigin, setlgaoforigin] = React.useState("");
  const [dateofbirth, setdateofbirth] = React.useState("");
  const [dataset, setdataset] = React.useState(undefined);
  const [lgaofresidence, setlgaofresidence] = React.useState("");
  const [lgaoriginlist, setlgaoriginlist] = React.useState("");
  const [lgaresidencelist, setlgaresidencelist] = React.useState("");
  const [loading, setloading] = React.useState(false);
  const [loadingInfo, setloadingInfo] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      const req = await axiosget("ms_applicant/bio");
      if (req.data?.type) {
        throw new Error(req.data.message);
      }
      const { data } = req;
      if (!is_null(data?.firstname)) {
        setdataset(data);
        setdisplay(data?.photo?.fulllink);
        setfirstname(data?.firstname);
        setmiddlename(data?.middlename);
        setlastname(data?.lastname);
        setgender(data?.gender);
        setdateofbirth(data?.dateofbirth);
        setreligion(data?.religion);
        setqualification(data?.qualification);
        setmaritalstatus(data?.maritalstatus);
        setemailaddress(data?.emailaddress);
        setphonenumber(data?.phonenumber);
        sethomeaddress(data?.homeaddress);
        setstateoforigin(data?.stateoforigin);
        setstateofresidence(data?.stateofresidence);
        setlgaoforigin(data?.lgaoforigin);
        setlgaofresidence(data?.lgaofresidence);
      } else {
        const { user } = context.values;
        if (!is_null(user)) {
          setfirstname(user.firstname);
          setmiddlename(user.middlename);
          setlastname(user.lastname);
          setemailaddress(user.emailaddress);
          setphonenumber(user.phonenumber);
        }
      }
      setloadingInfo(false);
    })();
  }, []);

  React.useEffect(() => {
    if (!is_null(stateoforigin)) {
      const selectedstate = states_lga.find((o) => o.state === stateoforigin);
      const lgas = selectedstate?.lgas
        ?.map((v) => ({ name: v, value: v }))
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      setlgaoriginlist(lgas);
    }
  }, [stateoforigin]);

  React.useEffect(() => {
    if (photo !== undefined) {
      setdisplay(URL.createObjectURL(photo));
    }
  }, [photo]);
  React.useEffect(() => {
    if (!is_null(stateofresidence)) {
      const selectedstate = states_lga.find(
        (o) => o.state === stateofresidence
      );
      const lgas = selectedstate?.lgas
        ?.map((v) => ({ name: v, value: v }))
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      setlgaresidencelist(lgas);
    }
  }, [stateofresidence]);

  const _login = async () => {
    try {
      setloading(true);
      if (
        is_null(firstname) ||
        is_null(lastname) ||
        is_null(gender) ||
        is_null(religion) ||
        is_null(qualification) ||
        is_null(maritalstatus) ||
        is_null(emailaddress) ||
        is_null(phonenumber) ||
        is_null(homeaddress) ||
        is_null(dateofbirth) ||
        is_null(stateoforigin) ||
        is_null(stateofresidence) ||
        is_null(lgaoforigin) ||
        is_null(lgaofresidence)
      ) {
        swal("warning", "Please fill the form properly", setloading, false);
        return;
      }
      let pix = null;
      if (!is_null(photo)) {
        const fd = new FormData();
        fd.append("photo", photo);
        const upload = await axios.post("https://doc.samtec.ng/index.php", fd);
        if (!is_null(upload?.data)) {
          if (upload.data?.type === "error") {
            throw new Error(upload.data?.message);
          }
        }
        pix = upload.data;
      } else {
        if (!is_null(dataset)) {
          pix = dataset.photo;
        }
      }
      if (is_null(pix)) {
        swal(
          "warning",
          "Sorry, system could not detect your picture",
          setloading,
          false
        );
        return;
      }

      const req = await axiospost("ms_applicant/bio", {
        photo: pix,
        firstname,
        middlename,
        lastname,
        gender,
        religion,
        dateofbirth,
        qualification,
        maritalstatus,
        emailaddress,
        phonenumber,
        homeaddress,
        stateoforigin,
        stateofresidence,
        lgaoforigin,
        lgaofresidence,
      });
      if (req.status !== 200) {
        throw new Error(
          "Sorry, system could not finish processing your request"
        );
      }
      const { data } = req;
      if (data && data.type === "success") {
        const { message, type } = data;
        swal(type, message, setloading, true);
      } else {
        throw new Error(data.message);
      }
    } catch ({ message }) {
      swal("warning", message, setloading);
    }
  };

  const states = states_lga
    .map((v) => ({ name: v.state, value: v.state }))
    .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
  return (
    <>
      {loadingInfo ? (
        <Loader />
      ) : (
        <div className="pt-1 mb-3">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="firstname">
                      First Name <b className="text-danger">*</b>
                    </label>
                    <input
                      className="form-control form-control-lg"
                      id="firstname"
                      required
                      placeholder="First Name*"
                      onChange={(e) => setfirstname(e.target.value)}
                      value={firstname}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="middlename">Middle Name</label>
                    <input
                      className="form-control form-control-lg"
                      id="middlename"
                      placeholder="Middle Name"
                      onChange={(e) => setmiddlename(e.target.value)}
                      value={middlename}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="lastname">
                      Last Name <b className="text-danger">*</b>
                    </label>
                    <input
                      className="form-control form-control-lg"
                      id="exampleInputEmail1"
                      placeholder="Last Name*"
                      onChange={(e) => setlastname(e.target.value)}
                      value={lastname}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  {states && (
                    <SelectStatus
                      options={states}
                      label="Select State of Origin"
                      callback={setstateoforigin}
                      active={stateoforigin}
                    />
                  )}
                </div>
                <div className="col-md-6">
                  {lgaoriginlist && (
                    <SelectStatus
                      options={lgaoriginlist}
                      label="Select Local Gov. Area of Origin"
                      callback={setlgaoforigin}
                      active={lgaoforigin}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  {states && (
                    <SelectStatus
                      options={states}
                      label="Select State of Residence"
                      callback={setstateofresidence}
                      active={stateofresidence}
                    />
                  )}
                </div>
                <div className="col-md-6">
                  {lgaresidencelist && (
                    <SelectStatus
                      options={lgaresidencelist}
                      label="Select Local Gov. Area of Residence"
                      callback={setlgaofresidence}
                      active={lgaofresidence}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  {states && (
                    <SelectStatus
                      options={[
                        { name: "Male", value: "Male" },
                        { name: "Female", value: "Female" },
                      ]}
                      label="Select Gender"
                      callback={setgender}
                      active={gender}
                    />
                  )}
                </div>
                <div className="col-md-6">
                  <SelectStatus
                    options={[
                      { name: "Muslim", value: "Muslim" },
                      { name: "Christain", value: "Christain" },
                      { name: "Others", value: "Others" },
                    ]}
                    label="Select Religion"
                    callback={setreligion}
                    active={religion}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <div className="text-center">
                  <img
                    className="id-template"
                    src={!is_null(display) ? display : `images/id.jpg`}
                    alt="ID Template"
                  />
                </div>
                <label htmlFor="passport" className="text-center">
                  Clear Passport Photograph (white background color,
                  min-width:200px, min-height:300px)
                  <b className="text-danger">*</b>
                </label>
                <input
                  className="form-control form-control-lg"
                  id="passport"
                  required
                  type="file"
                  onChange={(e) =>
                    image_processor(e.target.files[0], setphoto, 200, 300)
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="dateofbirth">
                  Date of Birth <b className="text-danger">*</b>
                </label>
                <input
                  className="form-control form-control-lg"
                  id="exampleInputEmail1"
                  placeholder="Date of Birth*"
                  type="date"
                  onChange={(e) => setdateofbirth(e.target.value)}
                  value={dateofbirth}
                />
              </div>
            </div>
            <div className="col-md-4">
              {states && (
                <SelectStatus
                  options={[
                    {
                      name: "Primary School Certificate",
                      value: "Primary School Certificate",
                    },
                    {
                      name: "Secondary/O-Level Certificate",
                      value: "Secondary/O-Level Certificate",
                    },
                    {
                      name: "National Diploma (ND)",
                      value: "National Diploma (ND)",
                    },
                    {
                      name: "Nigeria Certificate in Education (NCE)",
                      value: "Nigeria Certificate in Education (NCE)",
                    },
                    {
                      name: "Higher National Diploma (HND)",
                      value: "Higher National Diploma (HND)",
                    },
                    {
                      name: "University Degree",
                      value: "University Degree",
                    },
                    {
                      name: "Masters Degree",
                      value: "Masters Degree",
                    },
                    {
                      name: "PhD. Degree",
                      value: "PhD. Degree",
                    },
                  ]}
                  label="Select Highest Qualification"
                  callback={setqualification}
                  active={qualification}
                />
              )}
            </div>
            <div className="col-md-4">
              <SelectStatus
                options={[
                  { name: "Single", value: "Single" },
                  { name: "Married", value: "Married" },
                  { name: "Divorced", value: "Divorced" },
                  { name: "Widowed", value: "Widowed" },
                ]}
                label="Select Marital Status"
                callback={setmaritalstatus}
                active={maritalstatus}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="lastname">Email Address</label>
                <input
                  type="email"
                  className="form-control form-control-lg"
                  id="exampleInputEmail1"
                  placeholder="Email Address"
                  onChange={(e) => setemailaddress(e.target.value)}
                  value={emailaddress}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="lastname">
                  Phone Number <b className="text-danger">*</b>
                </label>
                <input
                  type="email"
                  className="form-control form-control-lg"
                  id="exampleInputEmail1"
                  placeholder="Phone Number*"
                  onChange={(e) => setphonenumber(e.target.value)}
                  value={phonenumber}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="lastname">
              Home Address <b className="text-danger">*</b>
            </label>
            <textarea
              type="email"
              className="form-control form-control-lg"
              id="exampleInputEmail1"
              placeholder="Home Address*"
              onChange={(e) => sethomeaddress(e.target.value)}
              value={homeaddress}
            />
          </div>
          <div className="mt-3">
            {!loading && (
              <button
                className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                onClick={_login}
              >
                SAVE
              </button>
            )}
            {loading && (
              <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">
                LOADING...
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default BioDataForm;
