import React from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import MainDashboard from "../../content/MainDashboard";
import { contextapi } from "../../helpers/contextapi";

const Dashboard = () => {
  const context = React.useContext(contextapi);
  React.useEffect(() => {
    const { user } = context.values;
    if (user === undefined) {
      const record = JSON.parse(localStorage.getItem("isApplicantData"));
      context.setvalues({ ...context.values, user: record });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <MainDashboard />
      </div>
    </div>
  );
};

export default Dashboard;
