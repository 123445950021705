import React from "react";

const ShowIJMB = ({ r }) => {
  const {
    institution,
    graduationyear,
    subject1,
    subject1grade,
    subject2,
    subject2score,
    subject3,
    subject3score,
  } = r?.results;
  return (
    <>
      <div className="row">
        <div className="col-md-4">Institution</div>
        <div className="col-md-8">{institution}</div>
      </div>
      <div className="row">
        <div className="col-md-4">Graduation Year</div>
        <div className="col-md-8">{graduationyear}</div>
      </div>
      <div className="row">
        <div className="col-md-4">Subject 1</div>
        <div className="col-md-4">{subject1}</div>
        <div className="col-md-4">{subject1grade}</div>
      </div>
      <div className="row">
        <div className="col-md-4">Subject 2</div>
        <div className="col-md-4">{subject2}</div>
        <div className="col-md-4">{subject2score}</div>
      </div>
      <div className="row">
        <div className="col-md-4">Subject 3</div>
        <div className="col-md-4">{subject3}</div>
        <div className="col-md-4">{subject3score}</div>
      </div>
    </>
  );
};

export default ShowIJMB;
