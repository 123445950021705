import React from "react";
import Loader from "../../components/Loader";
import { contextapi } from "../../helpers/contextapi";
import { axiosget, axiospost } from "../../helpers/httprequest";
import Swal from "sweetalert2";
import swal from "../../helpers/swal";
import is_money from "../../helpers/is_money";
import SelectStatus from "../../components/SelectStatus";
import { APIKEY, MerchantId } from "../../components/Keys";
import CryptoJS from "crypto-js";
import ReactModal from "react-modal";
import html2canvas from "html2canvas";
import ReceiptHeader from "../../components/ReceiptHeader";
import QRCode from "react-qr-code";
import ConfirmAcceptance from "../../components/ConfirmAcceptance";

const MainAcceptance = () => {
  const context = React.useContext(contextapi);
  const [rrr, setrrr] = React.useState(undefined);
  const [data, setdata] = React.useState(undefined);
  const [rawitems, setrawitems] = React.useState(undefined);
  const [rawadmission, setrawadmission] = React.useState(undefined);
  const [paymentitem, setpaymentitem] = React.useState(null);
  const [admission, setadmission] = React.useState(null);
  const { user } = context.values;
  const [selectedadmission, setselectedadmission] = React.useState(null);
  const [loading, setloading] = React.useState(false);
  const [loading_p, setloading_p] = React.useState(false);
  const [showmodal, setshowmodal] = React.useState(false);
  //creating function to load ip address from the API
  React.useEffect(() => {
    (async () => {
      setloading(true);
      try {
        const req = await axiosget("ms_acceptance_fee/student");
        if (req.data && req.data.type === undefined) {
          const d = req?.data
            ?.map((r) => ({
              name: `${r.description} (${is_money(r.amount)})`,
              value: r._id,
            }))
            .sort((a, b) =>
              a.description > b.description
                ? 1
                : b.description > a.description
                ? -1
                : 0
            );
          setdata(d);
          setrawitems(req.data);
          const rea = await axiosget("ms_applications/admitted");
          if (rea.data) {
            const p = rea?.data
              ?.map((r) => ({
                name: `${r.progcode} ${r.progname}`,
                value: r._id,
              }))
              .sort((a, b) =>
                a.description > b.description
                  ? 1
                  : b.description > a.description
                  ? -1
                  : 0
              );
            setadmission(p);
            setrawadmission(rea?.data);
          }
          setloading(false);
          return;
        }
        throw new Error(req.data.message);
      } catch ({ message }) {
        setloading(false);
        Swal.fire({
          icon: "error",
          html: message,
          title: "ERROR ENCOUNTERED",
        });
      }
    })();
  }, []);

  const _close = () => setshowmodal(false);
  const _generate = async () => {
    try {
      setshowmodal(true);
      setloading_p(true);
      const req = await axiosget(
        `ms_acceptance_fee_rrr/${paymentitem}/${selectedadmission}`
      );
      const ri = rawitems?.filter((r) => r._id === paymentitem);
      const rad = rawadmission?.filter((r) => r._id === selectedadmission);
      if (req?.data?.type) {
        const serviceTypeId = ri[0].serviceID;
        const d = new Date();
        const orderId = d.getTime();
        const merchantId = MerchantId;
        const apiKey = APIKEY;
        const amount = ri[0].amount;

        var apiHash = CryptoJS.SHA512(
          merchantId + serviceTypeId + orderId + amount + apiKey
        );
        const dataRRR = {
          serviceTypeId: serviceTypeId,
          amount: amount,
          orderId: orderId,
          payerName: `${user.firstname} ${user.middlename}  ${user.lastname}`,
          payerEmail: user?.emailaddress,
          payerPhone: user?.phonenumber,
          description: `Payment for ${ri[0].description}`,
        };

        const headerRRR = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `remitaConsumerKey=${merchantId},remitaConsumerToken=${apiHash}`,
          },
          method: "POST",
          body: JSON.stringify(dataRRR),
        };

        fetch(
          "https://login.remita.net/remita/exapp/api/v1/send/api/echannelsvc/merchant/api/paymentinit",
          headerRRR
        )
          .then((r) => r.text())
          .then(async (r) => {
            const removeF = JSON.parse(
              r.substring(r.indexOf("{"), r.lastIndexOf("}") + 1)
            );
            if (removeF?.RRR === undefined) {
              setshowmodal(false);
              swal("error", removeF.statusMessage, setloading_p, false);
              return;
            }
            const fetch = await axiospost("ms_acceptance_fee_rrr", {
              paymentitem,
              application: selectedadmission,
              rrr: removeF.RRR,
              rrrdata: dataRRR,
            });
            if (fetch?.status !== 200) {
              swal(
                "error",
                "Sorry, system could not store your RRR Code, please try and generate another RRR code",
                undefined,
                false
              );
              return;
            }
            if (fetch?.data?.type === "error") {
              swal("error", fetch?.data?.message, undefined, false);
              return;
            }
            setrrr({ ri: ri[0], rad: rad[0], data: fetch.data });
            setloading_p(false);
          });
      } else {
        setrrr({ ri: ri[0], rad: rad[0], data: req.data });
        setloading_p(false);
      }
    } catch ({ message }) {
      swal("error", message, undefined, false);
    }
  };
  const _print = async () => {
    html2canvas(document.querySelector("#printDocument"), {
      dpi: 144,
      scale: 5,
      useCORS: true,
    }).then(async (canvas) => {
      var t = canvas.toDataURL().replace("data:image/png;base64,", "");
      await downloadBase64File("image/png", t, "image");
    });
  };
  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };
  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="row">
          <div className="col-md-12 grid-margin transparent">
            <div className="row">
              <div className="col-md-12 stretch-card transparent">
                <div className="card card-white">
                  <div className="card-body">
                    <h3 className="font-weight-bold text-uppercase">
                      Admission Acceptance Fee
                    </h3>
                    <hr />
                    <div className="row">
                      <div className="col-md-12 grid-margin transparent">
                        {loading ? (
                          <Loader />
                        ) : (
                          <>
                            <ReactModal
                              isOpen={showmodal}
                              className="ReactModal"
                              onRequestClose={_close}
                            >
                              <div
                                className="modal-dialog"
                                role="document"
                                style={{ minWidth: "80vw" }}
                              >
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title text-uppercase text-center">
                                      Payment Invoice Receipt
                                    </h5>
                                    <span
                                      className="text-danger pointer"
                                      onClick={_close}
                                    >
                                      &times;
                                    </span>
                                  </div>

                                  <div className="modal-body">
                                    {loading_p ? (
                                      <Loader />
                                    ) : (
                                      <div
                                        id="printDocument"
                                        style={{
                                          content: " ",
                                          display: "block",
                                          backgroundImage:
                                            rrr && rrr?.data?.status
                                              ? `url('./images/paid.png')`
                                              : `url('./images/not-paid.png')`,
                                          backgroundRepeat: "no-repeat",
                                          backgroundPosition: "50% 0",
                                          backgroundSize: "550px 550px",
                                        }}
                                      >
                                        <ReceiptHeader
                                          office={`PAYMENT RECEIPT Bursary Department`}
                                        />
                                        <div className="container">
                                          <div className="row">
                                            <div className="col-md-8">
                                              <div className="row">
                                                <div className="col-md-12">
                                                  Name:{" "}
                                                  <b>
                                                    {" "}
                                                    {rrr &&
                                                      rrr.data?.details
                                                        ?.payerName}
                                                  </b>
                                                </div>
                                                <div className="col-md-12">
                                                  Time:{" "}
                                                  <b>
                                                    {rrr && rrr.data?.createdAt}
                                                  </b>
                                                </div>
                                                <div className="col-md-12">
                                                  Admission Code:{" "}
                                                  <b>
                                                    {" "}
                                                    {rrr && rrr?.rad?.code}
                                                  </b>
                                                </div>
                                                <div className="col-md-12">
                                                  Email Address:{" "}
                                                  {rrr && (
                                                    <b>
                                                      {
                                                        rrr?.data?.details
                                                          ?.payerEmail
                                                      }
                                                    </b>
                                                  )}
                                                </div>
                                                <div className="col-md-12">
                                                  Phone Number:{" "}
                                                  {rrr && (
                                                    <b>
                                                      {
                                                        rrr?.data?.details
                                                          ?.payerPhone
                                                      }
                                                    </b>
                                                  )}
                                                </div>
                                                <div className="col-md-12">
                                                  Amount:{" "}
                                                  {rrr && (
                                                    <b>
                                                      {is_money(
                                                        rrr?.data?.details
                                                          ?.amount
                                                      )}
                                                    </b>
                                                  )}
                                                </div>
                                                <div className="col-md-12">
                                                  Transaction Status:{" "}
                                                  <b>
                                                    {rrr && rrr?.data?.status
                                                      ? "Paid"
                                                      : "Not Paid"}
                                                  </b>
                                                </div>
                                                <div className="col-md-12">
                                                  Programme:{" "}
                                                  <b>
                                                    {rrr && rrr?.rad?.progcode}{" "}
                                                    {rrr && rrr?.rad?.progname}
                                                  </b>
                                                </div>
                                                <div className="col-md-12 text-center">
                                                  REMITA RRR
                                                </div>
                                                <div className="col-md-12 text-center">
                                                  <h1 style={{ fontSize: 75 }}>
                                                    {rrr && rrr.data?.RRR}
                                                  </h1>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-4 mt-1">
                                              {rrr && (
                                                <QRCode
                                                  value={btoa(
                                                    JSON.stringify({
                                                      rrr: rrr.data?.RRR,
                                                      name: rrr.data?.details
                                                        ?.payerName,
                                                      admission: rrr?.rad?.code,
                                                      amount:
                                                        rrr?.data?.details
                                                          ?.amount,
                                                      description:
                                                        rrr?.data?.details
                                                          ?.description,
                                                    })
                                                  )}
                                                />
                                              )}
                                            </div>
                                            <div className="col-md-12 text-center">
                                              {rrr &&
                                                rrr?.data?.details?.description}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      className="btn btn-primary"
                                      onClick={_print}
                                    >
                                      <i className="fa fa-download"></i>{" "}
                                      Download Receipt
                                    </button>
                                    <ConfirmAcceptance
                                      application={admission}
                                      transaction={rrr && rrr.data?.RRR}
                                    />
                                  </div>
                                </div>
                              </div>
                            </ReactModal>
                            <div className="row">
                              <div className="col-md-4">
                                <SelectStatus
                                  options={data}
                                  label="Select item You wish to pay for"
                                  callback={setpaymentitem}
                                />
                              </div>
                              <div className="col-md-4">
                                <SelectStatus
                                  options={admission}
                                  label="Select admission You wish to pay for"
                                  callback={setselectedadmission}
                                />
                              </div>
                              <div className="col-md-4 mt-4">
                                <button
                                  onClick={_generate}
                                  className="btn btn-primary btn-lg btn-block"
                                >
                                  Make Payment
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainAcceptance;
