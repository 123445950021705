import React from "react";
// import { axiosget } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import { contextapi } from "../../helpers/contextapi";

const ShowSittingForm = ({ sit }) => {
  const context = React.useContext(contextapi);
  const [loading, setloading] = React.useState(true);
  const [type, settype] = React.useState(null);
  const [center, setcenter] = React.useState(null);
  const [year, setyear] = React.useState(null);
  const [examnumber, setexamnumber] = React.useState(null);
  const { examinsitting } = context.values;
  // const { sej } = context?.values;

  React.useEffect(() => {
    (async () => {
      try {
        if (!is_null(examinsitting)) {
          const data = examinsitting[sit - 1];
          settype(data?.type);
          setcenter(data?.center);
          setyear(data?.year);
          setexamnumber(data?.examnumber);
        }
        setloading(false);
      } catch ({ message }) {}
    })();
  }, []);
  React.useEffect(() => {
    (async () => {
      try {
        if (
          !is_null(type) &&
          !is_null(center) &&
          !is_null(year) &&
          !is_null(examnumber)
        ) {
          if (is_null(examinsitting)) {
            context.setvalues({
              ...context.values,
              examinsitting: [{ sit, type, center, year, examnumber }],
            });
          } else {
            const filters = examinsitting.filter((r, i) => r.sit !== sit);
            filters.push({ sit, type, center, year, examnumber });
            context.setvalues({
              ...context.values,
              examinsitting: filters,
            });
          }
        }
      } catch ({ message }) {}
    })();
  }, [type, center, year, examnumber]);
  return (
    <div>
      {loading ? (
        <p>Please wait system is loading</p>
      ) : (
        <div>
          <b>Sitting {sit}</b>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="ExamType1">
                  Exam Type <span className="text-danger">*</span>
                </label>
                <select
                  onChange={(e) => {
                    try {
                      const { sej } = context?.values;
                      let localsej = [];
                      if (sej === undefined) {
                        localsej.push({ sit, value: e.target.value });
                      } else {
                        let noJohn = sej.filter((el) => el.sit !== sit);
                        localsej = [...noJohn, { sit, value: e.target.value }];
                      }
                      context.setvalues({ ...context.values, sej: localsej });
                      settype(e.target.value);
                    } catch (error) {}
                  }}
                  id="ExamType1"
                  className="form-control"
                >
                  <option value="">Select Option</option>
                  <option value="WAEC"> WAEC</option>
                  <option value="NECO"> NECO</option>
                  <option value="NABTEB"> NABTEB</option>
                  <option value="NBAIS"> NBAIS</option>
                  <option value="GCE"> GCE</option>
                  <option value="GRADE II"> GRADE II</option>
                </select>
                <small>{type && `${type} Selected`}</small>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="ExamCentre1">
                  Exam Centre <span className="text-danger">*</span>
                </label>
                <input
                  onChange={(e) => setcenter(e.target.value)}
                  type="text"
                  id="ExamCentre1"
                  className="form-control"
                  placeholder="Examination Centre"
                />
                <small>{center && `${center} Entered`}</small>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="ExamYear1">
                  Exam Year <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  id="ExamYear1"
                  onChange={(e) => setyear(e.target.value)}
                  name="ExamYear1"
                  min="1980"
                  max="2024"
                  className="form-control"
                  placeholder="Examination Year"
                />
                <small>{year && `${year} Entered`}</small>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="ExamsNumber1">
                  Exam Number <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  onChange={(e) => setexamnumber(e.target.value)}
                  id="ExamsNumber1"
                  className="form-control"
                  placeholder="Examination Number"
                />
                <small> {examnumber && `${examnumber} Entered`}</small>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShowSittingForm;
