import React from "react";
import ContinueApplication from "../../components/ContinueApplication";
import Loader from "../../components/Loader";
import { contextapi } from "../../helpers/contextapi";
import { axiosget } from "../../helpers/httprequest";

const MainDashboard = () => {
  const context = React.useContext(contextapi);
  const [data, setdata] = React.useState(undefined);
  const [loading, setloading] = React.useState(false);
  //creating function to load ip address from the API
  React.useEffect(() => {
    (async () => {
      setloading(true);
      try {
        const req = await axiosget("ms_applications/mydetailed");
        if (req.data) {
          setdata(req.data);
        }
      } catch ({ message }) {
        console.log(message);
      }
      setloading(false);
    })();
  }, []);
  const { user } = context.values;
  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="row">
          <div className="col-md-12 grid-margin transparent">
            <div className="row">
              <div className="col-md-12 stretch-card transparent">
                <div className="card card-white">
                  <div className="card-body">
                    <h3 className="font-weight-bold">
                      Welcome {user?.firstname}
                    </h3>
                    <h6 className="font-weight-normal mb-0">
                      Available applications are dark blue, while applications
                      that have expired red, completed applications are marked
                      green
                    </h6>
                    <hr />
                    <div className="row">
                      <div className="col-md-12 grid-margin transparent">
                        {loading ? (
                          <Loader />
                        ) : (
                          <div className="row">
                            {data && data?.length ? (
                              data?.map((v, i) => (
                                <ContinueApplication data={v} key={i} />
                              ))
                            ) : (
                              <div className="text-muted p-3">
                                No application at the moment
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainDashboard;
