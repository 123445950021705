import React from "react";
import ReactModal from "react-modal";
import { axiospost } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";

const BtnCollege = ({ modules }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [firstname, setfirstname] = React.useState("");
  const [middlename, setmiddlename] = React.useState("");
  const [lastname, setlastname] = React.useState("");
  const [emailaddress, setemailaddress] = React.useState("");
  const [phonenumber, setphonenumber] = React.useState("");
  const [homeaddress, sethomeaddress] = React.useState("");
  const [loading, setloading] = React.useState("");

  const _save = async () => {
    try {
      setloading(true);
      if (
        is_null(firstname) ||
        is_null(lastname) ||
        is_null(phonenumber) ||
        is_null(homeaddress)
      ) {
        swal("warning", "Please fill the form properly", setloading);
        return;
      }
      const req = await axiospost("ms_applicant_referee", {
        firstname,
        middlename,
        lastname,
        emailaddress,
        phonenumber,
        homeaddress,
      });
      if (req.status !== 200) {
        throw new Error(
          "Sorry, system could not finish processing your request"
        );
      }
      const { data } = req;
      if (data && data.type === "success") {
        const { message, type } = data;
        swal(type, message, setloading, true);
      } else {
        throw new Error(data.message);
      }
    } catch ({ message }) {
      swal("warning", message, setloading);
    }
  };

  const _close = () => {
    setshowmodal(false);
  };
  const _show = () => {
    setshowmodal(true);
  };

  return (
    <div>
      <ReactModal
        isOpen={showmodal}
        onRequestClose={() => setshowmodal(false)}
        className="ReactModal"
      >
        <div className="modal-dialog " role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center">
                Parents and Guadians Form
              </h5>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="firstname">
                      First Name <b className="text-danger">*</b>
                    </label>
                    <input
                      className="form-control form-control-lg"
                      id="firstname"
                      required
                      placeholder="First Name*"
                      onChange={(e) => setfirstname(e.target.value)}
                      value={firstname}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="middlename">Middle Name</label>
                    <input
                      className="form-control form-control-lg"
                      id="middlename"
                      placeholder="Middle Name"
                      onChange={(e) => setmiddlename(e.target.value)}
                      value={middlename}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="lastname">
                  Last Name <b className="text-danger">*</b>
                </label>
                <input
                  className="form-control form-control-lg"
                  id="exampleInputEmail1"
                  placeholder="Last Name*"
                  onChange={(e) => setlastname(e.target.value)}
                  value={lastname}
                />
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="lastname">Email Address</label>
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      id="exampleInputEmail1"
                      placeholder="Email Address"
                      onChange={(e) => setemailaddress(e.target.value)}
                      value={emailaddress}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="lastname">
                      Phone Number <b className="text-danger">*</b>
                    </label>
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      id="exampleInputEmail1"
                      placeholder="Phone Number*"
                      onChange={(e) => setphonenumber(e.target.value)}
                      value={phonenumber}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="lastname">
                  Home Address <b className="text-danger">*</b>
                </label>
                <textarea
                  type="email"
                  className="form-control form-control-lg"
                  id="exampleInputEmail1"
                  placeholder="Home Address*"
                  onChange={(e) => sethomeaddress(e.target.value)}
                  value={homeaddress}
                />
              </div>
              <div className="modal-footer">
                {loading ? (
                  <button type="button" className="btn btn-primary">
                    Please wait...
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={_save}
                  >
                    Save changes
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={_close}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>

      <div>
        {!modules && (
          <div
            style={{
              position: "fixed",
              right: "50px",
              bottom: "50px",
            }}
          >
            <button
              className="btn btn-icon btn-primary btn-rounded"
              onClick={_show}
            >
              <i className="ti-plus"></i>
            </button>
          </div>
        )}
      </div>
      <div>
        {modules && (
          <button
            className="btn btn-icon btn-primary btn-rounded"
            onClick={_show}
          >
            <i className="ti-pencil"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default BtnCollege;
