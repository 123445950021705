import React from "react";
import { contextapi } from "../../helpers/contextapi";
import { axiosget } from "../../helpers/httprequest";

const LoadUploads = ({ requirement }) => {
  const context = React.useContext(contextapi);
  const [loading, setloading] = React.useState(true);
  const [status, setstatus] = React.useState(0);
  React.useEffect(() => {
    (async () => {
      try {
        const { selectedApplication } = context.values;
        const req = await axiosget(
          `ms_application_uploads/verify/${requirement}/${selectedApplication._id}`
        );
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        setstatus(req.data);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
  }, []);
  return <>{loading ? "Please wait..." : status?.verify}</>;
};

export default LoadUploads;
