import React from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import MainApplicationMenu from "../../content/MainApplicationMenu";

const Uploads = () => {
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <MainApplicationMenu />
      </div>
    </div>
  );
};

export default Uploads;
