import React from "react";
import { axiospost } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";
import Modal from "react-modal";
import image_processor from "../../helpers/image_processor";
import axios from "axios";
import { contextapi } from "../../helpers/contextapi";
import { UploadLink } from "../../helpers/uri";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const UploadDocument = ({ modules }) => {
  const navigate = useNavigate();
  const [showmodal, setshowmodal] = React.useState(false);
  const [image, setimage] = React.useState(undefined);
  const [display, setdisplay] = React.useState(undefined);
  const [loading, setloading] = React.useState(false);
  const context = React.useContext(contextapi);

  const _save = async () => {
    try {
      const { selectedApplication } = context.values;
      if (selectedApplication === undefined) {
        Swal.fire({
          title: "ERROR DETECTED",
          icon: "error",
          html: "Sorry, system detected a problem and cannot continue with your application, System will redirect you to your dashboard, click the application again and continue",
        }).then((r) => {
          navigate("/dashboard");
        });
      }
      setloading(true);
      if (is_null(image)) {
        swal("error", "Please fill the form properly.", setloading, false);
        return;
      }

      const fd = new FormData();
      fd.append("photo", image);
      const upload = await axios.post(
        UploadLink,

        fd
      );
      if (!is_null(upload?.data)) {
        if (upload.data?.type === "error") {
          throw new Error(upload.data?.message);
        }
      }
      let pix = upload.data;

      const req = await axiospost(`ms_application_uploads`, {
        requirement: modules._id,
        uri: pix,
        application: selectedApplication._id,
      });
      if (req.status !== 200)
        throw new Error("Sorry something went wrong, please try again");
      const { type, message } = req.data;
      if (type !== "success") {
        swal(type, message, undefined, false);
      } else {
        swal(type, message, setloading, true);
      }
    } catch ({ message }) {
      swal("error", message, setloading);
    }
  };

  React.useEffect(() => {
    (async () => {
      if (image !== undefined) {
        try {
          setdisplay(URL.createObjectURL(image));
        } catch ({ message }) {
          console.warn(message);
        }
        setloading(false);
      }
    })();
  }, [image]);

  const _close = () => {
    setshowmodal(false);
  };
  const _show = () => {
    setshowmodal(true);
  };

  return (
    <div>
      <Modal
        isOpen={showmodal}
        onRequestClose={() => setshowmodal(false)}
        className="ReactModal"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center text-uppercase">
                Upload {modules.label}
              </h5>
            </div>
            <div className="modal-body">
              <div className="text-wrap my-4 text-md">
                Please select the <b>IMAGE</b> scan of the document you wish to
                upload (min width: 500, height:700):
              </div>
              <div className="form-group">
                <input
                  type="file"
                  className="form-control form-control-lg"
                  id="exampleInputEmail2"
                  placeholder="Delete"
                  onChange={(e) =>
                    image_processor(e.target.files[0], setimage, 500, 700)
                  }
                />
                {display && (
                  <div className="uploadedDocument_div">
                    <img
                      alt="uploaded"
                      src={display}
                      className="uploadedDocument"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              {loading ? (
                <button type="button" className="btn btn-primary">
                  Please wait...
                </button>
              ) : (
                <>
                  {image && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={_save}
                    >
                      Save Document
                    </button>
                  )}
                </>
              )}

              <button type="button" className="btn btn-danger" onClick={_close}>
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div>
        <button
          className="btn btn-icon btn-primary btn-rounded"
          onClick={_show}
        >
          <i className="icon-upload"></i>
        </button>
      </div>
    </div>
  );
};

export default UploadDocument;
