import React from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import MainReceipt from "../../content/MainReceipt";

const Receipt = () => {
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <MainReceipt />
      </div>
    </div>
  );
};

export default Receipt;
