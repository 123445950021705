import React from "react";

const ShowNCE = ({ r }) => {
  const {
    institution,
    year,
    edusubject,
    edusubjectscore,
    subject3,
    subject3score,
    subject2,
    subject2score,
  } = r?.results;
  return (
    <>
      <div className="row my-3">
        <div className="col-md-4">Institution</div>
        <div className="col-md-8">{institution}</div>
      </div>
      <div className="row my-3">
        <div className="col-md-4">Graduation Year</div>
        <div className="col-md-8">{year}</div>
      </div>
      <div className="row my-3">
        <div className="col-md-4">Education Subject</div>
        <div className="col-md-4">{edusubject}</div>
        <div className="col-md-4">{edusubjectscore}</div>
      </div>
      <div className="row my-3">
        <div className="col-md-4">Subject 2</div>
        <div className="col-md-4">{subject2}</div>
        <div className="col-md-4">{subject2score}</div>
      </div>
      <div className="row my-3">
        <div className="col-md-4">Subject 3</div>
        <div className="col-md-4">{subject3}</div>
        <div className="col-md-4">{subject3score}</div>
      </div>
    </>
  );
};

export default ShowNCE;
