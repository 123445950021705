import React from "react";
import ShowIJMB from "./showijmb";
import ShowDiploma from "./showdiploma";
import ShowNCE from "./showdnce";
import DeleteALevel from "../../buttons/DeleteALevel";

const TblALevel = ({ data }) => {
  console.log(data);
  return (
    <div className="row">
      {data &&
        data?.map((r, i) => (
          <div key={`the_key_${i}`} className="col-md-12">
            <div className="card">
              <div className="card-header">
                <div className="d-flex">
                  <div>{r.type}</div>
                  <div className="flex-grow"></div>
                  <div className="">
                    <DeleteALevel id={r._id} />
                  </div>
                </div>
              </div>
              <div className="card-body">
                {r.type === "IJMB" && <ShowIJMB r={r} />}
                {r.type === "Diploma" && <ShowDiploma r={r} />}
                {r.type === "NCE" && <ShowNCE r={r} />}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default TblALevel;
