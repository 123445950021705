import React from "react";
import { useNavigate } from "react-router-dom";
import moment from "../../helpers/moment";

const AdmissionLetter = ({ data }) => {
  const navigate = useNavigate();

  return (
    <div className="col-md-12 stretch-card transparent mt-4 applicant_menu">
      <div className={`card card-dark-green`}>
        <div
          className="card-body"
          onClick={() => navigate(`/letter/${data._id}`)}
        >
          <div className="row">
            <div className="col-md-4">
              <span className="applicant_menu_span">{data?.progcode}</span>
            </div>
            <div className="col-md-8 pt-4">
              <p>{data?.progname}</p>
              <p>{data?.code}</p>
              <p>Application Fee {data?.ispaid ? "Paid" : "Not Paid"}</p>
              <p>Started Application {moment(data?.createdAt)} ago</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdmissionLetter;
