import React from "react";

const Diploma = ({ data, setdata }) => {
  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="firstname">
              Name of Institution <b className="text-danger">*</b>
            </label>
            <input
              className="form-control form-control-lg"
              id="firstname"
              required
              placeholder="Name of Institution*"
              onChange={(e) =>
                setdata({ ...data, institution: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="middlename">
              Course of Study <b className="text-danger">*</b>
            </label>
            <input
              className="form-control form-control-lg"
              id="middlename"
              placeholder="Course of Study"
              onChange={(e) => setdata({ ...data, course: e.target.value })}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="firstname">
              Classification of Diploma <b className="text-danger">*</b>
            </label>
            <select
              className="form-control"
              onChange={(e) =>
                setdata({ ...data, classification: e.target.value })
              }
            >
              <option>---</option>
              <option value="First Class">First Class</option>
              <option value="Second Class Upper">Second Class Upper</option>
              <option value="Second Class Lower">Second Class Lower</option>
              <option value="Third Class">Third Class</option>
              <option value="Pass">Pass</option>
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="diplomatype">
              Diploma Type <b className="text-danger">*</b>
            </label>
            <select
              className="form-control"
              id="diplomatype"
              onChange={(e) =>
                setdata({ ...data, diplomatype: e.target.value })
              }
            >
              <option>---</option>
              <option value="National Diploma">National Diploma</option>
              <option value="Advance National Diploma">
                Advance National Diploma
              </option>
            </select>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="middlename">
              CGPA<b className="text-danger">*</b>
            </label>
            <input
              className="form-control form-control-lg"
              id="middlename"
              placeholder="CGPA"
              onChange={(e) => setdata({ ...data, cgpa: e.target.value })}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="middlename">
              Year of Graduation<b className="text-danger">*</b>
            </label>
            <input
              className="form-control form-control-lg"
              id="middlename"
              placeholder="Year of Graduation"
              onChange={(e) =>
                setdata({ ...data, graduationyear: e.target.value })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Diploma;
