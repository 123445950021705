import React from "react";
import { useNavigate } from "react-router-dom";
import { contextapi } from "../../helpers/contextapi";
import { axiospost } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";
import { Link } from "react-router-dom";

const Login = () => {
  const [loading, setloading] = React.useState(false);
  const username = React.useRef();
  const password = React.useRef();
  const context = React.useContext(contextapi);
  const navigate = useNavigate();
  React.useEffect(() => {
    localStorage.clear();
    context.setvalues({ ...context.values, main_menu: undefined });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const _login = async () => {
    try {
      setloading(true);
      const uname = username.current.value;
      const upass = password.current.value;
      if (is_null(uname) || is_null(upass)) {
        swal("warning", "Please fill the form properly", setloading);
        return;
      }
      const req = await axiospost("ms_applicant/login", {
        emailaddress: uname,
        password: upass,
      });
      if (req.status !== 200) {
        throw new Error(
          "Sorry, system could not finish processing your request"
        );
      }
      const { data } = req;
      if (data.token) {
        const { user, token, verify } = data;
        if (!verify) {
          swal(
            "success",
            "Your login is successful, but your account is not verified",
            () => navigate("/verify"),
            false
          );
          return;
        }
        localStorage.setItem("isApplicant", token);
        localStorage.setItem("isApplicantData", JSON.stringify(user));
        context.setvalues({ ...context.values, user: user });

        swal(
          "success",
          "Your login is successful",
          () => navigate("/dashboard"),
          false
        );
      } else {
        throw new Error(data.message);
      }
    } catch ({ message }) {
      swal("warning", message, setloading);
    }
  };
  return (
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-7 mx-auto">
              <div className="auth-form-light text-left py-3 px-4 px-sm-5">
                <div className="brand-logo text-center">
                  <img src="images/logo.jpg" alt="logo" />
                  <img
                    src="images/buk.jpeg"
                    style={{ maxWidth: 100 }}
                    alt="BUK logo"
                  />
                </div>
                <div className="text-center">
                  <h4>ADAMU TAFAWA BALEWA COLLEGE OF EDUCATION, KANGERE</h4>
                  <h6 className="font-weight-light">
                    ADMISSION APPLICATION PORTAL FOR UNDERGRADUATE
                  </h6>
                  <h6 className="font-weight-light text-uppercase">
                    Sign in to continue
                  </h6>
                </div>
                <div className="pt-3">
                  <div className="form-group">
                    <label htmlFor="emailaddress">Email Address</label>
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      id="emailaddress"
                      placeholder="Email Address"
                      ref={username}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      className="form-control form-control-lg"
                      id="password"
                      placeholder="Password"
                      ref={password}
                    />
                  </div>
                  <div className="mt-3">
                    {!loading && (
                      <button
                        className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                        onClick={_login}
                      >
                        SIGN IN
                      </button>
                    )}
                    {loading && (
                      <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">
                        Please wait...
                      </button>
                    )}
                  </div>
                  <div className="my-2 d-flex justify-content-between align-items-end">
                    <Link to="/account" className="auth-link text-black">
                      Create Account?
                    </Link>
                    <Link to="/forgot" className="auth-link text-black">
                      Forgot password?
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
