import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Loader from "../components/Loader";
import is_null from "./is_null";
import { contextapi } from "./contextapi";
import { axiosget } from "./httprequest";

const ProtectedRoute = ({ children }) => {
  const [loading, setloading] = useState(true);
  const [ispermitted, setispermitted] = useState(false);
  const isauth = localStorage.getItem("isApplicant");
  const context = React.useContext(contextapi);
  useEffect(() => {
    (async () => {
      try {
        let login = false;
        const req = await axiosget(`ms_applicant/permit`);
        if (req.data) {
          if (req.data.permit) {
            login = true;
          }
        } else {
          if (is_null(isauth)) {
            setispermitted(login);
            setloading(false);
            return;
          }
        }

        const { user, selectedApplication } = context.values;
        let userRecord;
        if (
          is_null(user) &&
          !is_null(localStorage.getItem("isApplicantData"))
        ) {
          userRecord = JSON.parse(localStorage.getItem("isApplicantData"));
        }

        let application;
        if (
          is_null(selectedApplication) &&
          !is_null(localStorage.getItem("applicationId"))
        ) {
          application = JSON.parse(localStorage.getItem("applicationId"));
        }

        context.setvalues({
          ...context.values,
          selectedApplication: application,
          user: userRecord,
        });

        setispermitted(login);
        setloading(false);
      } catch ({ message }) {
        console.error(message);
      }
    })();
  }, []);
  return (
    <>
      {!loading ? (
        <>{ispermitted ? children : <Navigate to="/" />}</>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ProtectedRoute;
